import { MenuOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { animateScroll } from 'react-scroll';

import Link, { ScrollLink } from '@entsoe/app-base/components/Layout/Link';
import Logo from '@entsoe/app-base/components/Logo';

import { IMenuProps } from '../../../typings';
import { MenuToggle } from './styles';

// if mobile -> slider = top with vertical content col row
const Menu = ({
    config = [],
    logo = true,
    mobileBreakpoint = 800,
}: IMenuProps): JSX.Element => {
    const [viewportWidth, setViewportWidth] = useState(0);
    const [isMenuShown, setIsMenuShown] = useState(false);

    const isMobile = () => viewportWidth < mobileBreakpoint;

    const toggleMenu = () => {
        setIsMenuShown(!isMenuShown);
    };

    const onClose = () => {
        setIsMenuShown(false);
    };

    const setWidth = () => {
        onClose();
        setViewportWidth(window.innerWidth);
    };

    useEffect(() => {
        setViewportWidth(window.innerWidth);

        window.addEventListener('resize', setWidth);

        return () => window.removeEventListener('resize', setWidth);
    }, []);

    const partition = (array, filter) => {
        const right = [],
            left = [];
        array.forEach((e, idx, arr) =>
            (filter(e, idx, arr) ? right : left).push(e)
        );
        return [left, right];
    };

    const [left, right] = partition(config, item => item.right);

    const getMenuContent = (): JSX.Element => {
        return isMobile() ? (
            <>
                <MenuToggle onClick={toggleMenu}>
                    <MenuOutlined />
                </MenuToggle>
                <Drawer
                    title="menu"
                    placement="top"
                    closable={false}
                    onClose={onClose}
                    visible={isMenuShown}
                    height={config.length * 37 + 130}
                    headerStyle={{ height: '82px' }}
                >
                    <Row gutter={[12, 12]}>
                        {config.map((item, index) => {
                            return (
                                <Col key={index} span={24}>
                                    <Link text={item.text} url={item.url} />
                                </Col>
                            );
                        })}
                    </Row>
                </Drawer>
            </>
        ) : (
            <Row justify="space-between" wrap={false}>
                <Col flex="auto">
                    {left.map((item, index) => {
                        return (
                            <>
                                <Link
                                    key={index}
                                    text={item.text}
                                    url={item.url}
                                />
                                {index !== left.length - 1 && (
                                    <RightOutlined
                                        style={{ fontSize: '14px' }}
                                    />
                                )}
                            </>
                        );
                    })}
                </Col>
                {right.length > 0 && (
                    <Col flex="none">
                        {right.map((item, index) => {
                            return item.spy ? (
                                <ScrollLink
                                    key={index}
                                    activeClass="link-active"
                                    spy={true}
                                    smooth={true}
                                    duration={500}
                                    offset={-82}
                                    to={item.url}
                                >
                                    <span>{item.text}</span>
                                </ScrollLink>
                            ) : (
                                <Link
                                    key={index}
                                    text={item.text}
                                    url={item.url}
                                    icon={item.icon}
                                />
                            );
                        })}
                    </Col>
                )}
            </Row>
        );
    };

    return (
        <Row wrap={false}>
            {logo && (
                <Col
                    flex="150px"
                    onClick={() => {
                        animateScroll.scrollToTop();
                    }}
                >
                    <Logo variant="light" />
                </Col>
            )}
            <Col flex="auto">{getMenuContent()}</Col>
        </Row>
    );
};

export default Menu;
