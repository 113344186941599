import { types, cast } from 'mobx-state-tree';

export const Indicator = types.model('Indicator', {
    id: types.identifierNumber,
    name: types.string,
    unit: types.string,
    explainer: types.string,
    options: types.frozen(),
    text: types.frozen(),
});

const IndicatorStore = types
    .model({
        data: types.optional(types.array(Indicator), []),
        initialized: types.optional(types.boolean, false),
    })
    .actions(self => ({
        // TODO fix typings
        setData(data) {
            self.data = cast(data);
            self.initialized = true;
        },
    }))
    .views(self => {
        return {
            getIndicatorById(id: number) {
                return self.data.find(item => item.id === id);
            },
        };
    });

export default IndicatorStore;
