import { useEffect } from 'react';

interface IUseDebounceProps {
    callback: () => void;
    delay?: number;
    dependencies?: any[];
}

const useDebounce = ({
    callback,
    delay = 1000,
    dependencies = [],
}: IUseDebounceProps): void => {
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [...dependencies]);
};

export default useDebounce;
