import styled from 'styled-components';

export const IntroContainer = styled.header`
    background-image: linear-gradient(135deg, #0f218b, #00947f);
    min-height: 50vh;
    width: 100%;

    color: #fff;
    text-align: center;
    padding: 2em 1em;
    font-size: 1.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    h1 {
        color: #fff;
    }

    table {
        width: 100%;
        margin: 1em 0;

        /* th {
            color: #B9037E;
            font-size: 2em
        } */
    }
`;
