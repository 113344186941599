import { useEffect, useState } from 'react';

import bounds from '../config/bounds';
import { IUseMapData, IUseCountryHoverProps } from '../types';

export const useCountryHover = ({
    map,
    onClick,
    isLoaded = false,
    data = [],
    disabled = false,
}: IUseCountryHoverProps): IUseMapData => {
    const SOURCE_ID = 'source-country-hover';
    const LAYER_ID = 'hover-layer';

    const [bbox, setBbox] = useState<number[][]>();

    const clearSources = () => {
        if (map.getLayer(LAYER_ID)) {
            map.removeLayer(LAYER_ID);
        }

        if (map.getSource(SOURCE_ID)) {
            map.removeFeatureState({
                source: SOURCE_ID,
                sourceLayer: 'country_boundaries',
            });
            map.removeSource(SOURCE_ID);
        }
    };

    useEffect(() => {
        if (!map || !isLoaded) {
            return;
        }

        clearSources();

        map.addSource(SOURCE_ID, {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
            promoteId: 'iso_3166_1',
        });

        let countryCode = null;

        map.addLayer(
            {
                id: LAYER_ID,
                type: 'fill',
                source: SOURCE_ID,
                'source-layer': 'country_boundaries',
                layout: {},
                paint: {
                    'fill-color': [
                        'case',
                        ['!=', ['feature-state', 'hoverable'], null],
                        [
                            'case',
                            ['==', ['feature-state', 'hover'], true],
                            '#28ACBF',
                            'rgba(255,255,255,0)',
                        ],
                        'rgba(255,255,255,0)',
                    ],
                },
            },
            'country-label'
        );

        map.on('mousemove', LAYER_ID, mapElement => {
            if (mapElement.features.length > 0) {
                if (countryCode !== null) {
                    map.setFeatureState(
                        {
                            source: SOURCE_ID,
                            sourceLayer: 'country_boundaries',
                            id: countryCode,
                        },
                        { hover: false }
                    );
                }
                countryCode = mapElement.features[0].properties.iso_3166_1;
                map.setFeatureState(
                    {
                        source: SOURCE_ID,
                        sourceLayer: 'country_boundaries',
                        id: countryCode,
                    },
                    { hover: true }
                );
            }
        });

        map.on('mouseleave', LAYER_ID, () => {
            if (countryCode !== null) {
                map.setFeatureState(
                    {
                        source: SOURCE_ID,
                        sourceLayer: 'country_boundaries',
                        id: countryCode,
                    },
                    { hover: false }
                );
            }
            countryCode = null;
        });

        map.on('click', LAYER_ID, mapElement => {
            const cb = () => {
                const cc = mapElement.features[0].properties.iso_3166_1;
                const isClickable = mapElement.features[0].state?.hoverable;
                if (cc && isClickable) {
                    onClick && onClick(cc);
                }
            };
            if (
                ['pen', 'touch'].includes(mapElement.originalEvent.pointerType)
            ) {
                if (mapElement.originalEvent.detail === 2) {
                    cb();
                }
            } else {
                cb();
            }
        });

        return () => {
            clearSources();
        };
    }, [map, isLoaded]);

    useEffect(() => {
        const boundaries = [];

        if (isLoaded) {
            if (map?.getSource(SOURCE_ID)) {
                map.removeFeatureState({
                    source: SOURCE_ID,
                    sourceLayer: 'country_boundaries',
                });
            }

            if (!data.length) {
                return;
            }

            for (const item of data) {
                bounds[item.key] &&
                    boundaries.push(
                        [bounds[item.key][0], bounds[item.key][1]],
                        [bounds[item.key][2], bounds[item.key][3]]
                    );

                if (item.value && !disabled) {
                    [item.key, item.key2].forEach(id => {
                        if (id) {
                            map.setFeatureState(
                                {
                                    source: SOURCE_ID,
                                    sourceLayer: 'country_boundaries',
                                    id,
                                },
                                {
                                    hoverable: true,
                                }
                            );
                        }
                    });
                }
            }

            setBbox(boundaries);
        }
    }, [data, isLoaded]);

    return {
        bbox,
    };
};
