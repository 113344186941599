import styled from 'styled-components';

export const PopupCotainer = styled.div`
    max-width: 150px;
    text-align: center;
`;

export const PopupTitle = styled.p`
    font-weight: 700;
    margin-bottom: 0px;
`;

export const PopupItem = styled.p`
    font-size: 0.9em;
    margin-bottom: 0px;
`;
