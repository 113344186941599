import { Col, Row, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import BarChart from '@entsoe/charts/components/BarChart';
import MapContainer from '@entsoe/map/containers/Map/';
import { Type } from '@entsoe/map/types/type';

import { useRootStore } from '../../../context/rootContext';
import { ExplorerModeType } from '../../../types';
import { GridType } from '../../../types/grid';
import { UiType } from '../../../types/ui';
import { StyledCard } from '../CountryContainer/styles';

interface IRegionalContainerProps {
    type: ExplorerModeType;
}

const COLORS = {
    [GridType.NONE]: 'noGridColor',
    [GridType.IOSN]: 'ioSNGridColor',
    [GridType.PORTFOLIO]: 'portfolioGridColor',
};
const ViewContainer = ({ type }: IRegionalContainerProps) => {
    const { explorerStore, countryStore } = useRootStore();
    const {
        data,
        selectedIndicator,
        setSelectedCountry,
        setMode,
        grid,
        isLoading,
        year,
    } = explorerStore;

    const { getCountryByCode } = countryStore;

    const [uiType, setUiType] = useState('');
    const [unit, setUnit] = useState();

    useEffect(() => {
        setUiType(selectedIndicator?.options[type].type);
        setUnit(selectedIndicator?.unit);
    }, [selectedIndicator]);

    const onClick = (countryCode: string) => {
        setSelectedCountry(countryCode);
        setMode('country');
    };

    const getGlobalColors = () => {
        return selectedIndicator?.options.colors.global[COLORS[grid]];
    };

    const getLegendColors = () => {
        return selectedIndicator?.options.colors.legend;
    };

    switch (uiType) {
        case UiType.NETMAP:
            return (
                <MapContainer
                    type={Type.NET}
                    data={data}
                    unit={unit}
                    onClick={onClick}
                    options={{
                        hoverable: true,
                        animated: true,
                        interactive: true,
                        clickable: true,
                    }}
                    keyResolver={countryCode => {
                        return getCountryByCode(countryCode);
                    }}
                    isLoading={isLoading}
                    theme={{
                        color: getGlobalColors(),
                    }}
                />
            );
        case UiType.MAP:
            let legendTitle = '';
            switch (selectedIndicator?.id) {
                case 15:
                    legendTitle =
                        'Nominal transmission capacity / installed RES';
                    break;
                case 16:
                    legendTitle = 'Nominal transmission capacity / peak load';
                    break;
            }
            return (
                <MapContainer
                    type={Type.DEFAULT}
                    data={data}
                    unit={unit}
                    onClick={onClick}
                    options={{
                        hoverable: true,
                        animated: true,
                        interactive: true,
                        clickable: true,
                    }}
                    isLoading={isLoading}
                    theme={{
                        color: getGlobalColors(),
                        legend: getLegendColors(),
                    }}
                    legendTitle={legendTitle}
                />
            );
        case UiType.FLOWMAP:
            let min = null;
            let max = null;
            switch (selectedIndicator?.id) {
                case 12:
                    min = 0;
                    max = 100;
                    break;
            }
            return (
                <MapContainer
                    type={Type.FLOW}
                    data={data}
                    unit={unit}
                    onClick={onClick}
                    options={{
                        hoverable: true,
                        animated: true,
                        interactive: true,
                        clickable: true,
                    }}
                    keyResolver={countryCode => {
                        return getCountryByCode(countryCode);
                    }}
                    isLoading={isLoading}
                    theme={{
                        color: getGlobalColors(),
                    }}
                    min={min}
                    max={max}
                />
            );
        case UiType.CHARTMAP:
            return (
                <MapContainer
                    type={Type.CHART}
                    data={data}
                    unit={unit}
                    onClick={onClick}
                    options={{
                        hoverable: true,
                        animated: true,
                        interactive: true,
                        clickable: true,
                    }}
                    keyResolver={countryCode => {
                        return getCountryByCode(countryCode);
                    }}
                    isLoading={isLoading}
                    theme={{
                        legend: getLegendColors(),
                    }}
                    extendedLegend={year === 2040}
                    tooltipTitle="Capacity increases need"
                />
            );
        case UiType.DIFFMAP:
            return (
                <MapContainer
                    type={Type.DIFF}
                    data={data}
                    unit={unit}
                    onClick={onClick}
                    options={{
                        hoverable: true,
                        animated: true,
                        interactive: true,
                        clickable: true,
                    }}
                    keyResolver={countryCode => {
                        return getCountryByCode(countryCode);
                    }}
                    isLoading={isLoading}
                    theme={{
                        legend: getLegendColors(),
                    }}
                />
            );
        case UiType.BARCHART:
            return (
                <BarChart
                    data={data}
                    unit={unit}
                    onClick={onClick}
                    keyResolver={countryCode => {
                        return getCountryByCode(countryCode);
                    }}
                    isLoading={isLoading}
                    theme={{
                        type: 'default',
                        colors: getGlobalColors(),
                    }}
                />
            );
        case UiType.BARCHART2:
            let countryData = Object.entries(
                data.find(item => item.key === 'country')?.value ?? {}
            ).map(item => {
                return {
                    key: item[0],
                    value: item[1],
                };
            });
            let unitData = Object.entries(
                data.find(item => item.key === 'unit')?.value ?? {}
            ).map(item => {
                return {
                    key: item[0],
                    value: item[1],
                };
            });

            const componentSize =
                countryData.length > 0 && unitData.length > 0
                    ? 'small'
                    : 'large';

            return (
                <>
                    {countryData.length > 0 && (
                        <>
                            <Row>
                                <Col span={24}>
                                    <StyledCard
                                        size="small"
                                        bordered={false}
                                        title="Total generation per country"
                                    >
                                        <BarChart
                                            data={countryData}
                                            unit={unit}
                                            size={componentSize}
                                            isLoading={isLoading}
                                            onClick={onClick}
                                            theme={{
                                                type: 'default',
                                                colors: getGlobalColors(),
                                            }}
                                            keyResolver={countryCode => {
                                                return getCountryByCode(
                                                    countryCode
                                                );
                                            }}
                                        />
                                    </StyledCard>
                                </Col>
                            </Row>
                        </>
                    )}

                    {unitData.length > 0 && (
                        <>
                            <Row>
                                <Col span={24}>
                                    <StyledCard
                                        size="small"
                                        bordered={false}
                                        title="Total generation per type of generating unit"
                                    >
                                        <BarChart
                                            data={unitData}
                                            unit={unit}
                                            size={componentSize}
                                            isLoading={isLoading}
                                            theme={{
                                                type: 'default',
                                                colors: getGlobalColors(),
                                            }}
                                        />
                                    </StyledCard>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
            );
        default:
            return <Skeleton paragraph={{ rows: 12 }} active={true} />;
    }
};

export default observer(ViewContainer);
