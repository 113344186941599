import { tint } from 'polished';
import { useEffect, useState } from 'react';

import flowGeoJson from '../geojson/flow.json';
import { IUseMapData, IUseFlowsProps } from '../types';
import { usePopup } from './usePopup';

export const useFlows = ({
    map,
    min,
    max,
    isLoaded = false,
    data = [],
    keyResolver = value => value,
    unit,
    theme = {
        color: '#5e73ed',
    },
    size,
}: IUseFlowsProps): IUseMapData => {
    const SOURCE_ID = 'source-flows';
    const LAYER_ID = 'layer-flows';
    const LAYER_UX_ID = 'layer-ux-flows';

    const [bbox, setBbox] = useState<number[][]>();

    usePopup({
        map,
        isLoaded,
        layer: LAYER_UX_ID,
        size,
    });

    const clearSources = (removeSource: boolean) => {
        if (map.getLayer(LAYER_ID)) {
            map.removeLayer(LAYER_ID);
        }

        if (map.getLayer(LAYER_UX_ID)) {
            map.removeLayer(LAYER_UX_ID);
        }

        if (map.getSource(SOURCE_ID)) {
            map.removeFeatureState({
                source: SOURCE_ID,
            });
            removeSource && map.removeSource(SOURCE_ID);
        }
    };

    const addLayer = (minValue, maxValue) => {
        map.addLayer({
            id: LAYER_UX_ID,
            type: 'line',
            source: SOURCE_ID,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-width': [
                    'case',
                    ['>=', ['feature-state', 'value'], 0],
                    10,
                    0,
                ],
                'line-color': 'rgba(255,255,255,0)',
            },
        });
        map.addLayer(
            {
                id: LAYER_ID,
                type: 'line',
                source: SOURCE_ID,
                layout: {
                    'line-cap': 'round',
                    'line-join': 'round',
                },

                paint: {
                    // 'visibility': ''
                    'line-width': [
                        'case',
                        ['>=', ['feature-state', 'value'], 0],
                        [
                            'interpolate',
                            ['linear'],
                            ['feature-state', 'value'],
                            minValue - 0.001,
                            2,
                            maxValue,
                            10,
                        ],
                        0,
                    ],
                    'line-color': [
                        'case',
                        ['!=', ['feature-state', 'value'], null],
                        [
                            'interpolate',
                            ['linear'],
                            ['feature-state', 'value'],
                            minValue - 0.001,
                            tint(0.6, theme.color),
                            maxValue,
                            theme.color,
                        ],
                        'rgba(255,255,255,0)',
                    ],
                },
            },
            'country-label'
        );
    };

    useEffect(() => {
        if (!map || !isLoaded) {
            return;
        }

        clearSources(true);

        map.addSource(SOURCE_ID, {
            type: 'geojson',
            data: flowGeoJson,
            promoteId: 'id',
        });

        return () => {
            clearSources(true);
        };
    }, [map, isLoaded]);

    useEffect(() => {
        let boundaries = [];
        if (isLoaded) {
            clearSources(true);

            map.addSource(SOURCE_ID, {
                type: 'geojson',
                data: flowGeoJson,
                promoteId: 'id',
            });

            if (!data.length) {
                return;
            }
            addLayer(
                min ?? Math.min(...data.map(item => item.value)),
                max ?? Math.max(...data.map(item => item.value))
            );

            for (const item of data) {
                const countryCodes = [item.key, item.key2].sort();
                const id = `${countryCodes[0]}-${countryCodes[1]}`;

                const feature = flowGeoJson.features.find(el => el.id === id);

                if (feature)
                    boundaries = boundaries.concat(
                        feature?.geometry.coordinates
                    );

                map.setFeatureState(
                    {
                        source: SOURCE_ID,
                        id,
                    },
                    {
                        value: item.value,
                        key: item.key,
                        key2: item.key2,
                        popup: {
                            title: `${keyResolver(item.key)} - ${keyResolver(
                                item.key2
                            )}`,
                            values: [`${item.value} ${unit}`],
                        },
                    }
                );
            }
            setBbox(boundaries);
        }
    }, [data, isLoaded]);

    return {
        bbox,
    };
};
