import { createGlobalStyle } from 'styled-components';

const AppStyle = createGlobalStyle`
  #root {
      height: 100%
  }

  .mapboxgl-popup {
    z-index: 1001;

    &.mapbox-popup-small {
      transform: none !important;
      position: absolute !important;
      bottom: 0 !important;
      width: 100% !important;
      max-width: none !important;
      flex-direction: column-reverse !important;

      .mapboxgl-popup-tip {
        border: none;
      }
    }

    .mapboxgl-popup-content {
      background: rgba(255, 255, 255, 0.9);
      padding: 7px;
      box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.4);
    }

    &.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
      border-bottom-color: rgba(255, 255, 255, 0.9);
    }

    &.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
      border-left-color: rgba(255, 255, 255, 0.9);
    }

    &.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
      border-top-color: rgba(255, 255, 255, 0.9);
    }

    &.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
      border-right-color: rgba(255, 255, 255, 0.9);
    }
  }

  /* slides */
  .swiper-slide {
    width: auto !important;
  }

  .swiper-slide-prev,
  .swiper-slide-next {
    opacity: 0.3;
  }
  /* scroller */
  .link-active{
    color: #28acbf;
  }
  .link-active span:after {
    width: 100%;

  }
  
`;

export default AppStyle;
