import { Tabs } from 'antd';
import styled from 'styled-components';

export const StyledTabPane = styled(Tabs.TabPane)`
    height: 70vh;
    min-height: 670px;
`;

export const StyledCountryTabPane = styled(Tabs.TabPane)`
    height: 100%;
    min-height: 320px;
`;
