import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useRootStore } from '../../../context/rootContext';
import { getRegionsByIndicator } from '../../../services/apiService';

interface IRegionSelectorProps {
    hidden?: boolean;
}

const RegionSelector = ({
    hidden = true,
}: IRegionSelectorProps): JSX.Element => {
    const { explorerStore, regionStore } = useRootStore();
    const [options, setOptions] = useState([]);

    const { setData } = regionStore;

    const {
        // selectedIndicator,
        setSelectedRegion,
        selectedRegion,
        isLoading: isExplorerLoading,
    } = explorerStore;

    const { refetch, isLoading } = useQuery(
        ['regions' /* , selectedIndicator */],
        () => getRegionsByIndicator(/* selectedIndicator */),
        {
            enabled: false,
            onSuccess: ({ data }) => {
                setData(data);
                selectedRegion ?? setSelectedRegion(data[0].id);
                setOptions(
                    data.map(item => {
                        return {
                            value: item.id,
                            label: item.name,
                        };
                    })
                );
            },
        }
    );

    useEffect(() => {
        refetch();
    }, []);

    return (
        <Form.Item
            name="selectedRegion"
            label="Select PCI corridor"
            hidden={hidden}
        >
            <Select
                loading={isLoading}
                options={options}
                disabled={isLoading || isExplorerLoading}
            />
        </Form.Item>
    );
};

export default observer(RegionSelector);
