import styled from 'styled-components';

import { MapSizes } from './index';

export interface IMapProps {
    size: MapSizes;
}

const SIZE = {
    small: '250px',
    medium: '320px',
    large: '100%',
};

export const StyledMapContainer = styled.div<{
    size: MapSizes;
    isLoading?: boolean;
}>`
    width: 100%;
    height: ${({ size }) => SIZE[size ?? 'large']};
    position: relative;
    transition: all 1s;
    filter: ${({ isLoading }) => (isLoading ? 'blur(3px)' : 'auto')};
`;

export const StyledMap = styled.div<{ size: MapSizes }>`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: ${({ size }) => SIZE[size ?? 'large']};
`;
