import {
    FilePdfOutlined,
    RightOutlined,
    PlusOutlined,
    DownOutlined,
} from '@ant-design/icons';
import { Row, Col, Skeleton, Modal, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Element, scroller } from 'react-scroll';
import { Parallax } from 'react-scroll-parallax';

import Button from '@entsoe/app-base/components/Form/Input/Button';
import Card from '@entsoe/app-base/components/Layout/Card';
import Carousel from '@entsoe/app-base/components/Layout/Carousel';
// import Cover from '@entsoe/app-base/components/Layout/Cover';
import MarkdownStaticText from '@entsoe/app-base/components/Layout/MarkdownStaticText';
import MarkdownText from '@entsoe/app-base/components/Layout/MarkdownText';
import { Container } from '@entsoe/app-base/containers/Container';
import RegionalExplainer from '../../components/RegionalExplainer';
import { useRootStore } from '../../context/rootContext';
import { getText } from '../../services/apiService';
// import PdfIcon from '../../assets/icons/pdf.svg';
import Configuration from '../Explorer/Configuration';
import Explorer from '../Explorer/TabContainer';
import { StyledTitle } from './styles';
import { StyledExplainerIcon, StyledRow, TextContainer } from './styles';

const { Title } = Typography;

const EmbeddedContainer = () => {
    const { explorerStore, indicatorStore } = useRootStore();

    const { initialized } = indicatorStore;

    const { selectedIndicator } = explorerStore;

    const onIndicatorExplainer = () => {
        Modal.info({
            maskClosable: true,
            width: '90vw',
            title: selectedIndicator?.name,
            content: (
                <MarkdownStaticText value={selectedIndicator?.explainer} />
            ),
            onOk() {},
        });
    };

    return (
        <>
            <Parallax
                opacity={[0.7, 1]}
                shouldAlwaysCompleteAnimation={true}
                startScroll={0}
                endScroll={400}
                rootMargin={{ top: 250, left: 0, right: 0, bottom: 0 }}
            >
                <Element name="explorer">
                    <Container>
                        <Row>
                            <Col>
                                <StyledTitle level={4}>
                                    Data visualisation
                                </StyledTitle>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col>
                                <Element name="explorer-inner">
                                    <Skeleton
                                        paragraph={{ rows: 1, width: 200 }}
                                        active
                                        loading={!selectedIndicator?.name}
                                    >
                                        <Title id="explorer" level={5}>
                                            {selectedIndicator?.name}{' '}
                                            <StyledExplainerIcon
                                                onClick={onIndicatorExplainer}
                                            />
                                        </Title>
                                    </Skeleton>
                                </Element>
                            </Col>
                        </Row>
                        <Space
                            size="large"
                            direction="vertical"
                            style={{ width: '100%' }}
                        >
                            <StyledRow gutter={[16, 40]}>
                                <Col xs={24} md={18}>
                                    <Explorer />
                                </Col>
                                <Col md={6} xs={0}>
                                    <Configuration />
                                </Col>
                            </StyledRow>
                            <Row gutter={[16, 40]}>
                                <Col span={24}>
                                    <Card>
                                        <RegionalExplainer />
                                    </Card>
                                </Col>
                            </Row>
                        </Space>
                    </Container>
                </Element>
            </Parallax>
        </>
    );
};

export default observer(EmbeddedContainer);
