import { useEffect, useState } from 'react';

import diffArrowsGeoJson from '../geojson/diff.arrows.json';
import diffGeoJson from '../geojson/diff.json';
import { IUseMapData, IUseLinesProps } from '../types';
import { usePopup } from './usePopup';

export const useLines = ({
    type = 'circles',
    map,
    isLoaded = false,
    data = [],
    keyResolver = value => value,
    unit,
    expression,
    size,
    tooltipTitle,
}: IUseLinesProps): IUseMapData => {
    const SOURCE_ID = 'source-lines';
    const SOURCE_ARROWS_ID = 'source-arrows';
    const LAYER_ID = 'layer-lines';
    const LAYER_UX_ID = 'layer-ux-lines';
    const LAYER_ARROWS_ID = 'layer-arrows';

    usePopup({
        map,
        isLoaded,
        layer: LAYER_UX_ID,
        size,
    });

    const [bbox, setBbox] = useState<number[][]>();

    const clearSources = (removeSource: boolean) => {
        if (map.getLayer(LAYER_ID)) {
            map.removeLayer(LAYER_ID);
        }
        if (map.getLayer(LAYER_UX_ID)) {
            map.removeLayer(LAYER_UX_ID);
        }
        if (map.getSource(SOURCE_ID)) {
            map.removeFeatureState({
                source: SOURCE_ID,
            });
            removeSource && map.removeSource(SOURCE_ID);
        }
        if (map.getLayer(LAYER_ARROWS_ID)) {
            map.removeLayer(LAYER_ARROWS_ID);
        }
        if (map.getSource(SOURCE_ARROWS_ID)) {
            map.removeFeatureState({
                source: SOURCE_ARROWS_ID,
            });
            removeSource && map.removeSource(SOURCE_ARROWS_ID);
        }
    };

    const addLayer = () => {
        map.addLayer({
            id: LAYER_UX_ID,
            type: 'line',
            source: SOURCE_ID,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
            },
            paint: {
                'line-width': [
                    'case',
                    ['!=', ['feature-state', 'value'], null],
                    10,
                    0,
                ],
                'line-color': 'rgba(255,255,255,0)',
            },
        });

        map.addLayer({
            id: LAYER_ID,
            type: 'line',
            source: SOURCE_ID,
            layout: {
                'line-cap': 'round',
                'line-join': 'round',
                'line-round-limit': 5,
            },
            // TODO ;)
            paint: {
                'line-width': 2,
                'line-color': [
                    'case',
                    ['!=', ['feature-state', 'value'], null],
                    expression,
                    'rgba(255,255,255,0)',
                ],
            },
        });

        if (type === 'circles') {
            map.addLayer({
                id: LAYER_ARROWS_ID,
                type: 'circle',
                source: SOURCE_ARROWS_ID,

                // TODO ;)
                paint: {
                    'circle-radius': 3,
                    'circle-color': [
                        'case',
                        ['!=', ['feature-state', 'value'], null],
                        expression,
                        'rgba(255,255,255,0)',
                    ],
                },
            });
        } else {
            map.addLayer({
                id: LAYER_ARROWS_ID,
                type: 'symbol',
                source: SOURCE_ARROWS_ID,

                // TODO ;)
                layout: {
                    'icon-image': `custom-arrow`,
                    'icon-size': 0.19,
                    'icon-rotate': ['get', 'angle'],
                    'symbol-avoid-edges': true,
                    'icon-ignore-placement': true,
                    'icon-anchor': 'center',
                },
                paint: {
                    // 'icon-opacity': 0.9,
                    'icon-color': [
                        'case',
                        ['!=', ['feature-state', 'value'], null],
                        expression,
                        'rgba(0,0,0,0)',
                    ],
                },
            });
        }
    };

    useEffect(() => {
        if (!map || !isLoaded) {
            return;
        }

        if (type === 'arrows') {
            map.loadImage(
                'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABMElEQVRYhe2WL0gDURzHP8oQMRiGQQyytCBGgwyDyTiWZBhNpsVhXV40mMS4YDQZxLSiybA0FoZhYSwsLIxxaNj7jXG7ef/e753BL3zacZ8PHMd78L90OwZKWQa8AR/AZhbyS+DbcO1avgP0lwIGwK7LgMaSXGi6kheASUDAFCi6CHgKkAvP2vLzX+TChZY8B3xGCOiYZ63vJoJcqNmW54FhjIARsGcz4C6GXLi3JT8CZgkCZszPitR7SSAXXtPKyynkQiWpfAvoWgjoAdtJAuoW5MJtXPk+MLYYMAYO4gQ8WJQLj1HlJ4CnEOCZd4eurSAX2mHyqqJcuFon91+ztPgyrpUFXbO0aPjlhwRfs7SYGOdiLYdyoSXyEjq/XRgecLbB/Nw+9X8TR3vPyPuH9gOGHaFKAUhJFAAAAABJRU5ErkJggg==',
                (error, image) => {
                    if (!error) {
                        map.addImage('custom-arrow', image, {
                            sdf: true,
                        });
                    }
                }
            );
        }

        clearSources(true);

        map.addSource(SOURCE_ID, {
            type: 'geojson',
            data: diffGeoJson,
            promoteId: 'id',
        });

        map.addSource(SOURCE_ARROWS_ID, {
            type: 'geojson',
            data: diffArrowsGeoJson,
            promoteId: 'id',
        });

        return () => {
            clearSources(true);
        };
    }, [map, isLoaded]);

    useEffect(() => {
        let boundaries = [];

        if (isLoaded) {
            clearSources(true);

            map.addSource(SOURCE_ID, {
                type: 'geojson',
                data: diffGeoJson,
                promoteId: 'id',
            });

            map.addSource(SOURCE_ARROWS_ID, {
                type: 'geojson',
                data: diffArrowsGeoJson,
                promoteId: 'id',
            });

            if (!data.length) {
                return;
            }

            for (const item of data) {
                const countryCodes = [item.key, item.key2].sort();
                const id = `${countryCodes[0]}-${countryCodes[1]}`;

                const feature = diffGeoJson.features.find(el => el.id === id);

                if (feature)
                    boundaries = boundaries.concat(
                        feature?.geometry.coordinates
                    );

                map.setFeatureState(
                    {
                        source: SOURCE_ID,
                        id,
                    },
                    {
                        // #$%^!
                        value:
                            type === 'arrows'
                                ? Object.values(item.value)[0]
                                : item.value,
                        key: item.key,
                        key2: item.key2,
                        popup: {
                            title: `${keyResolver(item.key)} - ${keyResolver(
                                item.key2
                            )}`,
                            values: [
                                type === 'arrows'
                                    ? `${
                                          tooltipTitle ??
                                          Object.keys(item.value)[0]
                                      }: ${
                                          Object.values(item.value)[0]
                                      } ${unit}`
                                    : `${item.value} ${unit}`,
                            ],
                        },
                    }
                );

                map.setFeatureState(
                    {
                        source: SOURCE_ARROWS_ID,
                        id,
                    },
                    {
                        value:
                            type === 'arrows'
                                ? Object.values(item.value)[0]
                                : item.value,
                        label:
                            type === 'arrows'
                                ? Object.keys(item.value)[0]
                                : null,
                        key: item.key,
                        key2: item.key2,
                    }
                );
            }
            addLayer();

            setBbox(boundaries);
        }
    }, [data, isLoaded]);

    return {
        bbox,
    };
};
