import { useRef } from 'react';

import { StyledMap } from '../../../containers/Map/styles';
import { useCountryExpression } from '../../../hooks/useCountryExpression';
import { useCountryHover } from '../../../hooks/useCountryHover';
import { useFitBounds } from '../../../hooks/useFitBounds';
import { useFullscreen } from '../../../hooks/useFullscreen';
import { useMap } from '../../../hooks/useMap';
import { IMapProps } from '../../../types';
import { getCountryLegend, getCountryExpression } from '../../../utils';
import Legend from '../../Legend';

const Map = ({
    data = [],
    max,
    min,
    size = 'large',
    options: {
        interactive = false,
        legendCollapsible = false,
        animated = true,
    },
    onClick,
    keyResolver,
    theme = {
        color: '#5e73ed',
        legend: {},
    },
    legendTitle,
}: IMapProps): JSX.Element => {
    const mapContainer = useRef(null);
    const { map, isLoaded } = useMap({
        ref: mapContainer,
        accessToken: process.env.MAPBOX_TOKEN,
        options: {
            interactive,
            style: process.env.MAPBOX_STYLE,
        },
    });

    useFullscreen(map, size);

    useCountryExpression({
        type: 'group',
        map,
        isLoaded,
        data,
        min,
        max,
        keyResolver,
        theme,
        popup: false,
        size,
        expression: getCountryExpression(theme.legend?.countries),
    });
    const { bbox } = useCountryHover({
        map,
        isLoaded,
        onClick,
        data,
        disabled: size === 'small' || size === 'medium',
    });

    useFitBounds({
        map,
        animated,
        bboxes: [bbox],
        size,
    });

    return (
        <StyledMap ref={mapContainer} size={size}>
            <Legend
                collapsible={legendCollapsible}
                unit="%"
                legend={[
                    {
                        title: legendTitle,
                        type: 'groups',
                        arrow: 'block',
                        values: getCountryLegend(theme.legend?.countries),
                    },
                ]}
            />
        </StyledMap>
    );
};

export default Map;
