import { Button } from 'antd';
import { Link } from 'react-router-dom';

import Error from '@entsoe/app-base/components/Error/Error';

const Route404 = (): JSX.Element => {
    return (
        <Error
            size="large"
            status={404}
            message="Sorry, the page you visited does not exist."
            extra={<Link to="/">Back Home</Link>}
        />
    );
};

export default Route404;
