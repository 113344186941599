import { useState, useEffect, useRef } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';

import Progress from './styles';

const HttpProgress = (): JSX.Element => {
    const timeout = 100;

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    const [progress, setProgress] = useState(0);
    const [isActive, setIsActive] = useState(false);

    const timeoutRef = useRef(null);
    const completeTimeoutRef = useRef(null);

    const incProgress = (currentProgress = progress) => {
        if (currentProgress > 100) {
            return;
        }
        let inc = 0;
        if (currentProgress >= 0 && currentProgress < 25) {
            inc = (Math.random() * (5 - 3 + 1) + 3) / 100;
        } else if (currentProgress >= 25 && currentProgress < 65) {
            inc = (Math.random() * 3) / 100;
        } else if (currentProgress >= 65 && currentProgress < 90) {
            inc = (Math.random() * 2) / 100;
        } else if (currentProgress >= 90 && currentProgress < 99) {
            inc = 0.005;
        } else {
            inc = 0;
        }
        setProgress(currentProgress + inc * 100);
    };

    useEffect(() => {
        const activeQueries = isFetching + isMutating;
        if (activeQueries > 0 && !isActive) {
            setIsActive(true);
            setProgress(2);
        } else if (isActive && activeQueries == 0) {
            clearTimeout(timeoutRef.current);

            setProgress(100);
            completeTimeoutRef.current = setTimeout(() => {
                setIsActive(false);
                setProgress(0);
            }, 1000);
        }
    }, [isFetching, isMutating]);

    useEffect(() => {
        const activeQueries = isFetching + isMutating;
        if (activeQueries > 0 && isActive) {
            timeoutRef.current = setTimeout(() => {
                incProgress(progress);
            }, timeout);
        }
    }, [progress]);

    return (
        <Progress
            percent={progress}
            status="active"
            strokeWidth={3}
            strokeColor={{
                '0%': '#28ACBF',
                '100%': '#00947F',
            }}
        />
    );
};

export default HttpProgress;
