import {
    FilePdfOutlined,
    RightOutlined,
    PlusOutlined,
    DownOutlined,
} from '@ant-design/icons';
import { Row, Col, Skeleton, Modal, Space, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { Element, scroller } from 'react-scroll';
import { Parallax } from 'react-scroll-parallax';

import Button from '@entsoe/app-base/components/Form/Input/Button';
import Card from '@entsoe/app-base/components/Layout/Card';
import Carousel from '@entsoe/app-base/components/Layout/Carousel';
// import Cover from '@entsoe/app-base/components/Layout/Cover';
import MarkdownStaticText from '@entsoe/app-base/components/Layout/MarkdownStaticText';
import MarkdownText from '@entsoe/app-base/components/Layout/MarkdownText';
import { Container } from '@entsoe/app-base/containers/Container';
import Intro from '@entsoe/app-base/containers/Intro';

import Slide1 from '../../assets/slideshow/EE_SlideDeckBOOK1024_1.png';
import Slide2 from '../../assets/slideshow/EE_SlideDeckBOOK1024_2.png';
import Slide3 from '../../assets/slideshow/EE_SlideDeckBOOK1024_3.png';
import Slide4 from '../../assets/slideshow/EE_SlideDeckBOOK1024_4.png';
import Slide5 from '../../assets/slideshow/EE_SlideDeckBOOK1024_5.png';
import Slide6 from '../../assets/slideshow/EE_SlideDeckBOOK1024_6.png';
import Slide7 from '../../assets/slideshow/EE_SlideDeckBOOK1024_7.png';
import Slide8 from '../../assets/slideshow/EE_SlideDeckBOOK1024_8.png';
import Slide9 from '../../assets/slideshow/EE_SlideDeckBOOK1024_9.png';
import Slide10 from '../../assets/slideshow/EE_SlideDeckBOOK1024_10.png';
import Slide11 from '../../assets/slideshow/EE_SlideDeckBOOK1024_11.png';
import Slide12 from '../../assets/slideshow/EE_SlideDeckBOOK1024_12.png';
import Slide13 from '../../assets/slideshow/EE_SlideDeckBOOK1024_13.png';
import Slide14 from '../../assets/slideshow/EE_SlideDeckBOOK1024_14.png';
import Slide15 from '../../assets/slideshow/EE_SlideDeckBOOK1024_15.png';
import Slide16 from '../../assets/slideshow/EE_SlideDeckBOOK1024_16.png';
import RegionalExplainer from '../../components/RegionalExplainer';
import { useRootStore } from '../../context/rootContext';
import { getText } from '../../services/apiService';
// import PdfIcon from '../../assets/icons/pdf.svg';
import Configuration from '../Explorer/Configuration';
import Explorer from '../Explorer/TabContainer';
import { StyledTitle } from './styles';
import { StyledExplainerIcon, StyledRow, TextContainer } from './styles';

const { Title } = Typography;

const RootContainer = () => {
    const { explorerStore, indicatorStore } = useRootStore();

    const { initialized } = indicatorStore;

    const { selectedIndicator } = explorerStore;

    const onIndicatorExplainer = () => {
        Modal.info({
            maskClosable: true,
            width: '90vw',
            title: selectedIndicator?.name,
            content: (
                <MarkdownStaticText value={selectedIndicator?.explainer} />
            ),
            onOk() {},
        });
    };

    return (
        <>
            <Parallax
                speed={0}
                opacity={[1, 0, 'easeInSine']}
                shouldAlwaysCompleteAnimation={true}
                rootMargin={{ top: 0, left: 0, right: 0, bottom: -82 }}
            >
                <Element name="introduction">
                    <Intro>
                        <Container>
                            <Row>
                                <Col
                                    lg={{ span: 16, offset: 4 }}
                                    style={{ textAlign: 'center' }}
                                >
                                    <MarkdownText
                                        name="FuturePowerSystemNeeds"
                                        query={getText}
                                    />

                                    {initialized ? (
                                        <>
                                            <Button
                                                type="primary"
                                                size="large"
                                                onClick={() => {
                                                    scroller.scrollTo(
                                                        'slideshow',
                                                        {
                                                            duration: 800,
                                                            delay: 100,
                                                            smooth: 'easeInOutQuad',
                                                            offset: -82,
                                                        }
                                                    );
                                                }}
                                                icon={<RightOutlined />}
                                                hoverIcon={<DownOutlined />}
                                            >
                                                Key message slides
                                            </Button>
                                            <Button
                                                type="primary"
                                                size="large"
                                                onClick={() => {
                                                    scroller.scrollTo(
                                                        'explorer',
                                                        {
                                                            duration: 800,
                                                            delay: 100,
                                                            smooth: 'easeInOutQuad',
                                                            offset: -82,
                                                        }
                                                    );
                                                }}
                                                icon={<RightOutlined />}
                                                hoverIcon={<DownOutlined />}
                                            >
                                                Data visualisation
                                            </Button>
                                            <Button
                                                color="orange"
                                                type="primary"
                                                size="large"
                                                href="https://eepublicdownloads.blob.core.windows.net/public-cdn-container/tyndp-documents/TYNDP2022/public/system-needs-report.pdf"
                                                target="_blank"
                                                icon={<FilePdfOutlined />}
                                            >
                                                Download the report
                                            </Button>
                                        </>
                                    ) : (
                                        <Skeleton.Button
                                            active={true}
                                            size="large"
                                            style={{
                                                width: '180px',
                                                backgroundColor: 'transparent',
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Container>
                    </Intro>
                </Element>
            </Parallax>

            <Parallax
                opacity={[0.7, 1]}
                shouldAlwaysCompleteAnimation={true}
                startScroll={0}
                endScroll={400}
                rootMargin={{ top: 250, left: 0, right: 0, bottom: 0 }}
            >
                <Element name="slideshow">
                    <Container>
                        <Row>
                            <Col>
                                <StyledTitle level={4}>
                                    Key messages slideshow
                                </StyledTitle>
                            </Col>
                        </Row>
                    </Container>

                    <Carousel
                        images={[
                            Slide1,
                            Slide2,
                            Slide3,
                            Slide4,
                            Slide5,
                            Slide6,
                            Slide7,
                            Slide8,
                            Slide9,
                            Slide10,
                            Slide11,
                            Slide12,
                            Slide13,
                            Slide14,
                            Slide15,
                            Slide16,
                        ]}
                    />
                </Element>
            </Parallax>

            <Parallax
                opacity={[0.7, 1]}
                shouldAlwaysCompleteAnimation={true}
                startScroll={0}
                endScroll={400}
                rootMargin={{ top: 250, left: 0, right: 0, bottom: 0 }}
            >
                <Element name="explorer">
                    <Container>
                        <Row>
                            <Col>
                                <StyledTitle level={4}>
                                    Data visualisation
                                </StyledTitle>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col>
                                <Element name="explorer-inner">
                                    <Skeleton
                                        paragraph={{ rows: 1, width: 200 }}
                                        active
                                        loading={!selectedIndicator?.name}
                                    >
                                        <Title id="explorer" level={5}>
                                            {selectedIndicator?.name}{' '}
                                            <StyledExplainerIcon
                                                onClick={onIndicatorExplainer}
                                            />
                                        </Title>
                                    </Skeleton>
                                </Element>
                            </Col>
                        </Row>
                        <Space
                            size="large"
                            direction="vertical"
                            style={{ width: '100%' }}
                        >
                            <StyledRow gutter={[16, 40]}>
                                <Col xs={24} md={18}>
                                    <Explorer />
                                </Col>
                                <Col md={6} xs={0}>
                                    <Configuration />
                                </Col>
                            </StyledRow>
                            <Row gutter={[16, 40]}>
                                <Col span={24}>
                                    <Card>
                                        <RegionalExplainer />
                                    </Card>
                                </Col>
                            </Row>
                        </Space>
                    </Container>
                </Element>
            </Parallax>
            <TextContainer>
                <Container>
                    <Space
                        size="large"
                        direction="vertical"
                        style={{ width: '100%' }}
                    >
                        {/* <Space  direction=""/> */}
                        <Row gutter={[16, 40]} justify="center" align="top">
                            <Col xs={24} md={12}>
                                <Card color="#FCFCFC" bordered={true}>
                                    <MarkdownText
                                        name="SystemNeeds"
                                        query={getText}
                                    />
                                </Card>
                            </Col>
                            <Col xs={24} md={12}>
                                <Card color="#FCFCFC" bordered={true}>
                                    <MarkdownText
                                        name="AboutScenarios"
                                        query={getText}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        {/* <Space/> */}
                        <Row gutter={[16, 40]}>
                            <Col span={24}>
                                <Card color="#FCFCFC" bordered={true}>
                                    <MarkdownText
                                        name="SystemNeedsReport"
                                        query={getText}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                        scroller.scrollTo('slideshow', {
                                            duration: 800,
                                            delay: 100,
                                            smooth: 'easeInOutQuad',
                                            offset: -82,
                                        });
                                    }}
                                    icon={<PlusOutlined />}
                                >
                                    See the slides
                                </Button>

                                <Button
                                    type="primary"
                                    size="large"
                                    href="https://eepublicdownloads.blob.core.windows.net/public-cdn-container/tyndp-documents/TYNDP2022/public/system-needs-report.pdf"
                                    target="_blank"
                                    icon={<PlusOutlined />}
                                >
                                    Download the full report
                                </Button>
                                <Button
                                    color="orange"
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                        scroller.scrollTo('explorer', {
                                            duration: 800,
                                            delay: 100,
                                            smooth: 'easeInOutQuad',
                                            offset: -82,
                                        });
                                    }}
                                    icon={<DownOutlined />}
                                >
                                    Data visualisation
                                </Button>
                            </Col>
                        </Row>
                    </Space>
                </Container>
            </TextContainer>
        </>
    );
};

export default observer(RootContainer);
