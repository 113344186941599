import styled from 'styled-components';

export const LegendContainer = styled.div<{ collapsed?: boolean }>`
    position: absolute;
    z-index: 1000;
    top: 5px;
    left: 5px;
    background-color: rgba(255, 255, 255, 0.85);
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.4);
    max-width: 200px;
    font: 16px 'Lato', sans-serif;
    line-height: 1.4;
`;

export const LegendGroup = styled.div`
    margin-bottom: 5px;
`;

export const LegendToggle = styled.div`
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgY2xhc3M9Imljb24iIHZpZXdCb3g9IjAgMCAxMDI0IDEwMjQiPgogIDxwYXRoIGQ9Ik00MDggNDQyaDQ4MGM0LjQgMCA4LTMuNiA4LTh2LTU2YzAtNC40LTMuNi04LTgtOEg0MDhjLTQuNCAwLTggMy42LTggOHY1NmMwIDQuNCAzLjYgOCA4IDh6bS04IDIwNGMwIDQuNCAzLjYgOCA4IDhoNDgwYzQuNCAwIDgtMy42IDgtOHYtNTZjMC00LjQtMy42LTgtOC04SDQwOGMtNC40IDAtOCAzLjYtOCA4djU2em01MDQtNDg2SDEyMGMtNC40IDAtOCAzLjYtOCA4djU2YzAgNC40IDMuNiA4IDggOGg3ODRjNC40IDAgOC0zLjYgOC04di01NmMwLTQuNC0zLjYtOC04LTh6bTAgNjMySDEyMGMtNC40IDAtOCAzLjYtOCA4djU2YzAgNC40IDMuNiA4IDggOGg3ODRjNC40IDAgOC0zLjYgOC04di01NmMwLTQuNC0zLjYtOC04LTh6TTE0Mi40IDY0Mi4xTDI5OC43IDUxOWE4Ljg0IDguODQgMCAwIDAgMC0xMy45TDE0Mi40IDM4MS45Yy01LjgtNC42LTE0LjQtLjUtMTQuNCA2Ljl2MjQ2LjNhOC45IDguOSAwIDAgMCAxNC40IDd6Ii8+Cjwvc3ZnPgo=');
    cursor: pointer;
    width: 15px;
    height: 15px;
`;

export const Label = styled.span`
    font-size: 0.6em;
    font-weight: bolder;
    margin: 0 5px;
    color: ${props => props.color ?? 'auto'};
`;

export const Title = styled.p`
    font-size: 0.7em;
    font-weight: 800;
    margin-bottom: 0px;
    /* text-transform: uppercase; */
`;

// TODO color
export const Arrow = styled.div`
    width: 50px;
    height: 2px;
    margin-top: 5px;
    background-color: ${props => props.color ?? '#FF0'};
`;

// TODO color
export const Block = styled.div`
    width: 10px;
    height: 12px;
    background-color: ${props => props.color ?? '#FF0'};
`;

export const Flow = styled.div`
    height: 0.6em;
    width: 70px;
    border-radius: 3px;
    background-image: linear-gradient(
        to right,
        ${props => props.colorFrom},
        ${props => props.colorTo}
    );
`;
