import { FilePdfOutlined } from '@ant-design/icons';

import AppLayout from '@entsoe/app-base/containers/AppLayout';

import Routes from '../Routes';

const Main = () => {
    return (
        <AppLayout
            menuConfig={{
                logo: true,
                config: [
                    {
                        text: 'ENTSO-E Homepage',
                        url: 'https://www.entsoe.eu/',
                    },
                    {
                        text: 'TYNDP 2022',
                        url: 'https://tyndp.entsoe.eu/',
                    },
                    {
                        text: 'System needs Study',
                        url: '/',
                    },
                    {
                        text: 'Introduction',
                        url: 'introduction',
                        spy: true,
                        right: true,
                    },
                    {
                        text: 'Key message slides',
                        url: 'slideshow',
                        spy: true,
                        right: true,
                    },
                    {
                        text: 'Data visualisation',
                        url: 'explorer',
                        spy: true,
                        right: true,
                    },
                    {
                        text: 'Download the report',
                        icon: <FilePdfOutlined />,
                        url: 'https://eepublicdownloads.blob.core.windows.net/public-cdn-container/tyndp-documents/TYNDP2022/public/system-needs-report.pdf',
                        right: true,
                    },
                ],
            }}
        >
            <Routes />
        </AppLayout>
    );
};

export default Main;
