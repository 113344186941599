import styled from 'styled-components';

export const StyledBarContainer = styled.div<{ isLoading?: boolean }>`
    height: ${props => (props.size === 'small' ? '280px' : '70vh')};
    transition: all 1s;
    filter: ${({ isLoading }) => (isLoading ? 'blur(3px)' : 'auto')};
`;

export const PopupWrapper = styled.div`
    border-radius: 3px;
    padding: 7px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
`;

export const PopupTitle = styled.p`
    font-weight: 700;
    margin-bottom: 0px;
`;

export const PopupItem = styled.p`
    font-size: 0.9em;
    margin-bottom: 0px;
`;
