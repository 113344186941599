import { ResponsiveBar } from '@nivo/bar';
import { ScaleSpec } from '@nivo/scales';

import {
    PopupItem,
    PopupTitle,
    PopupWrapper,
    StyledBarContainer,
} from './styles';

type StackedData = {
    key: string;
    value: number;
};

// TODO refactor
type GroupedData = {
    key: string;
    no: number;
    iosn: number;
    portfolio: number;
};

interface IBarChartProps {
    data?: StackedData[] | GroupedData[];
    onClick?: (countryCode: string) => void;
    keyResolver?: (countryCode: string) => string;
    size?: 'small' | 'large';
    keys?: string[];
    groupMode?: 'grouped' | 'stacked';
    unit?: string;
    legend?: boolean;
    isLoading?: boolean;
    theme?: {
        type: 'grouped' | 'linear' | 'default';
        colors:
            | string
            | {
                  no: string;
                  portfolio: string;
                  iosn: string;
              };
    };
}

const BarChart = ({
    data = [],
    onClick,
    keyResolver = key => key,
    size = 'large',
    keys = ['value'],
    groupMode = 'stacked',
    unit = '',
    legend = false,
    isLoading = true,
    theme = {
        type: 'default',
        colors: '#28ACBF',
    },
}: IBarChartProps): JSX.Element => {
    const getScaleConfig = (): ScaleSpec => {
        const default_value = 200;
        const threshold = 20;
        const minValue =
            groupMode === 'stacked'
                ? Math.min(...data.map(item => item.value))
                : Math.min(
                      ...data.map(item =>
                          Math.min(item.no, item.iosn, item.portfolio)
                      )
                  );
        const maxValue =
            groupMode === 'stacked'
                ? Math.max(...data.map(item => item.value))
                : Math.max(
                      ...data.map(item =>
                          Math.max(item.no, item.iosn, item.portfolio)
                      )
                  );
        if (maxValue / minValue > threshold) {
            return { type: 'symlog', constant: minValue || default_value };
        } else {
            return {
                type: 'linear',
            };
        }
    };

    const getTooltip = ({ indexValue, value, color, id }) => {
        return (
            <PopupWrapper>
                {/* {groupMode === 'grouped' && (
                    <PopupTitle>{keyResolver(id)}</PopupTitle>
                )} */}
                <PopupTitle>{keyResolver(indexValue)}</PopupTitle>
                <PopupItem key={indexValue}>
                    {value} {unit}
                </PopupItem>
            </PopupWrapper>
        );
    };

    const getColor = (key, value) => {
        switch (theme.type) {
            case 'default':
                return theme.colors;
            case 'grouped':
                return theme.colors[key.id];
            case 'linear':
                return theme.colors[key.indexValue];
        }
    };

    return (
        <StyledBarContainer size={size} isLoading={isLoading}>
            <ResponsiveBar
                data={data}
                keys={keys}
                indexBy="key"
                margin={{
                    top: 20,
                    right: legend ? 130 : 60,
                    bottom: 80,
                    left: 60,
                }}
                padding={0.4}
                valueScale={getScaleConfig()}
                indexScale={{ type: 'band', round: true }}
                groupMode={groupMode}
                colors={(key, value) => getColor(key)}
                colorBy="indexValue"
                animate={true}
                enableLabel={false}
                axisTop={null}
                axisRight={null}
                enableGridY={true}
                gridYValues={5}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: unit,
                    legendPosition: 'middle',
                    legendOffset: -55,
                    tickValues: 5,
                }}
                axisBottom={{
                    tickRotation: -45,
                    format: v => {
                        const value = keyResolver(v);
                        return value.length > 12 ? (
                            <tspan>
                                {value.substring(0, 12) + '...'}
                                <title>{value}</title>
                            </tspan>
                        ) : (
                            value
                        );
                    },
                }}
                tooltip={getTooltip}
                onClick={(props, event) => {
                    const cb = () => {
                        if (props.data?.key) {
                            onClick && onClick(props.data?.key);
                        }
                    };
                    if (
                        ['pen', 'touch'].includes(
                            event.nativeEvent?.pointerType
                        )
                    ) {
                        if (event.nativeEvent?.detail === 2) {
                            cb();
                        }
                    } else {
                        cb();
                    }
                }}
                legends={
                    legend
                        ? [
                              {
                                  dataFrom: 'keys',
                                  data: Object.keys(theme.colors).map(key => {
                                      const label = keyResolver(key);
                                      return {
                                          color: theme.colors[key],
                                          key,
                                          label:
                                              label?.length > 15
                                                  ? label.slice(0, 15) + '...'
                                                  : label,
                                      };
                                  }),
                                  anchor: 'top-right',
                                  direction: 'column',
                                  justify: false,
                                  translateX: 130,
                                  translateY: 0,
                                  itemsSpacing: 2,
                                  itemWidth: 110,
                                  itemHeight: 20,
                                  itemDirection: 'left-to-right',
                                  itemOpacity: 1,
                                  symbolSize: 20,
                                  effects: [
                                      {
                                          on: 'hover',
                                          style: {
                                              itemOpacity: 0.7,
                                          },
                                      },
                                  ],
                              },
                          ]
                        : undefined
                }
                onMouseEnter={(_data, event) => {
                    event.target.style.opacity = 0.7;
                }}
                onMouseLeave={(_data, event) => {
                    event.target.style.opacity = 1.0;
                }}
                theme={{
                    fontSize: 10,
                    textColor: '#000',
                }}
            />
        </StyledBarContainer>
    );
};

export default BarChart;
