import styled from 'styled-components';

export const StickyWrapper = styled.div<{
    visible?: boolean;
    display?: boolean;
}>`
    background-color: #0f218b;
    color: #fff;
    cursor: pointer;
    opacity: 0.7;
    padding: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    position: fixed;
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

    transition: all 0.3s ease-in-out;
    animation: sticky-animation 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.8);

    &:hover {
        opacity: 1;
    }

    @media (max-width: 767px) {
        right: 0px;
        top: calc(50vh - 20px);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    /* @media (min-width: 768px) and (max-width: 1199px) {
        top: 100px;
        right: 0;
        width: 40px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    } */

    @keyframes sticky-animation {
        0% {
            -webkit-transform: translateX(40px);
            transform: translateX(40px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }
    }
`;
