import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';

import { MapSizes } from '../types/size';

const ALLOWED_SIZES = ['large'];
export const useFullscreen = (
    map: mapboxgl.Map | undefined,
    size: MapSizes
): void => {
    useEffect(() => {
        if (!map) {
            return;
        }
        if (ALLOWED_SIZES.includes(size)) {
            map.addControl(new mapboxgl.FullscreenControl());
        }
    }, [map, size]);
};
