import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { StickyWrapper } from './styles';

interface IStickyProps {
    children: React.ReactNode;
    onClick?: () => void;
    visible: boolean;
    bound?: string;
}
const Sticky = ({
    children,
    onClick = () => {
        return false;
    },
    visible,
    bound,
}: IStickyProps): JSX.Element => {
    const [isInViewport, setIsInViewport] = useState(false);

    const inViewPort = () => {
        if (!bound) {
            setIsInViewport(false);
        }
        const el = document.getElementById(bound);
        if (!el) {
            setIsInViewport(false);
        }
        const rect = el.getBoundingClientRect();
        const viewportHeight =
            window.innerHeight || document.documentElement.clientHeight;
        const rectMid = (rect.bottom + rect.top) / 2;
        setIsInViewport(rectMid > 0 && rectMid < viewportHeight);
    };

    useEffect(() => {
        window.addEventListener('scroll', inViewPort, false);

        return () => window.removeEventListener('scroll', inViewPort, false);
    }, []);

    return ReactDOM.createPortal(
        <>
            {isInViewport && (
                <StickyWrapper onClick={onClick} visible={visible}>
                    {children}
                </StickyWrapper>
            )}
        </>,
        document.body
    );
};

export default Sticky;
