import { StyledCard } from './styles';

interface ICardProps {
    children: React.ReactNode;
    color?: string;
    bordered?: boolean;
}
const Card = ({ children, color, bordered }: ICardProps): JSX.Element => {
    return (
        <StyledCard color={color} bordered={bordered}>
            {children}
        </StyledCard>
    );
};

export default Card;
