import styled from 'styled-components';

import EntsoeLogoNegative from '@entsoe/app-base/assets/entose_logo-h-neg.svg';
import EntsoeLogoPositive from '@entsoe/app-base/assets/entose_logo-h-pos.svg';

import { ILogoProps } from '.';

const variants = {
    dark: EntsoeLogoNegative,
    light: EntsoeLogoPositive,
};

export const LogoWrapper = styled.div`
    height: 82px;
    width: 100%;
    max-width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const LogoImage = styled.img.attrs(
    ({ variant = 'dark' }: ILogoProps) => ({
        src: variants[variant],
    })
)`
    width: 100%;
`;
