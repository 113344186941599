import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
    &.ant-card-small > .ant-card-body {
        padding: 0;
    }

    &.ant-card-small {
        > .ant-card-head {
            padding: 0;
            min-height: 30px;

            .ant-card-head-wrapper > .ant-card-head-title {
                font-weight: 600;
                padding: 0;
            }
        }
    }
`;
