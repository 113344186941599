import { Form, Radio } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useRootStore } from '../../../context/rootContext';
import { StyledRadioGroup } from './styles';

const YearSelector = (): JSX.Element => {
    const { explorerStore } = useRootStore();

    const { isLoading, selectedIndicator, setYear } = explorerStore;
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        switch (selectedIndicator?.id) {
            case 15:
            case 16:
                setYear(2030);
                setIsDisabled(true);
                break;
            default:
                setIsDisabled(false);
        }
    }, [selectedIndicator]);
    return (
        <Form.Item name="year" label="Select year">
            <StyledRadioGroup buttonStyle="solid" disabled={isLoading}>
                <Radio.Button value={2030}>2030</Radio.Button>
                <Radio.Button value={2040} disabled={isDisabled}>
                    2040
                </Radio.Button>
            </StyledRadioGroup>
        </Form.Item>
    );
};

export default observer(YearSelector);
