import { Progress as AntdProgress } from 'antd';
import styled from 'styled-components';

const Progress = styled(AntdProgress).attrs({
    showInfo: false,
})`
    z-index: 99999;
    margin: 0;
    line-height: 0px;
    height: 4px;
    position: fixed;

    .ant-progress-inner {
        height: 4px;

        .ant-progress-bg {
            height: 4px !important;
        }
    }
`;

export default Progress;
