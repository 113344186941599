import { Layout as AntdLayout } from 'antd';
import styled from 'styled-components';

export const Content = styled(AntdLayout.Content)`
    padding: 0px;
    margin-top: 82px;
    margin-bottom: 0rem;
    &.ant-layout-content {
        min-height: auto;
    }
`;

// TODO props fixed, flex
export const Header = styled(AntdLayout.Header)`
    position: fixed;
    z-index: 9999;
    width: 100%;
    background-color: #fff;
    border-bottom: 2px solid #eee;
    padding: 0;
    height: 82px;
    line-height: 82px;
`;

export const Footer = styled(AntdLayout.Footer)`
    text-align: center;
    padding: 1rem;
    background-color: #edf2f7;
    color: #718096;

    p {
        margin: 0;
    }
`;

const Layout = styled(AntdLayout)`
    height: 100%;
`;

export default Layout;
