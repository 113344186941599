export const getCountryExpression = (countries = []) => {
    // TODO sort asc
    const expression = ['step', ['feature-state', 'value']];
    if (countries.length < 2) {
        return ['step', ['feature-state', 'value'], '#28ACBF', 0, '#00947F'];
    }
    countries.forEach(option => {
        expression.push(option.color);
        expression.push(option.threshold);
    });
    expression.pop();
    return expression;
};

export const getCountryLegend = (countries = []) => {
    // TODO sort asc
    const config = [];
    countries.forEach((option, idx) => {
        if (idx === 0) {
            config.push({
                valueTo: option.threshold,
                color: option.color,
                description: option.description,
            });
        } else if (idx === countries.length - 1) {
            config.push({
                valueFrom: countries[idx - 1].threshold,
                color: option.color,
                description: option.description,
            });
        } else {
            config.push({
                valueFrom: countries[idx - 1].threshold,
                valueTo: option.threshold,
                color: option.color,
                description: option.description,
            });
        }
    });
    return config;
};
