import { Skeleton } from 'antd';
import { AxiosResponse } from 'axios';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import remarkGfm from 'remark-gfm';

import Error from '@entsoe/app-base/components/Error/Error';

interface IMarkdownTextResponse {
    name: string;
    value: string;
}
interface IMarkdownTextProps {
    name: string;
    query: (name: string) => Promise<AxiosResponse<IMarkdownTextResponse>>;
}

const MarkdownText = ({ name, query }: IMarkdownTextProps): JSX.Element => {
    const { data, error, isLoading } = useQuery([name], () => {
        return query(name);
    });

    if (error) return <Error size="small" />;
    return (
        <Skeleton
            loading={isLoading}
            active={true}
            paragraph={{
                rows: 7,
            }}
        >
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {data?.data?.value}
            </ReactMarkdown>
        </Skeleton>
    );
};

export default MarkdownText;
