import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import '@entsoe/app-base/theme/theme.default.less';

// import AuthProvider from '@entsoe/auth/context/authContext';
// import msalConfig from '@entsoe/auth/msalConfig';
import RootProvider from '../../context/rootContext';
// import httpClient from '../../services/httpService';
import Main from '../Main';
import AppStyle from './styles';

// TODO: move to api or utils
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
        },
    },
});

export const App = () => {
    // TODO use auth/container wrapper
    return (
        // <AuthProvider msalConfig={msalConfig} httpClient={httpClient}>
        <RootProvider>
            <QueryClientProvider client={queryClient}>
                <AppStyle />
                <ParallaxProvider>
                    <Router>
                        <Main />
                    </Router>
                </ParallaxProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </RootProvider>
        // </AuthProvider>
    );
};
