import { useRef } from 'react';

import { StyledMap } from '../../../containers/Map/styles';
import { useCountryHover } from '../../../hooks//useCountryHover';
import { useLines } from '../../../hooks//useLines';
import { useFitBounds } from '../../../hooks/useFitBounds';
import { useFullscreen } from '../../../hooks/useFullscreen';
import { useMap } from '../../../hooks/useMap';
import { IMapProps } from '../../../types';
import { getCountryLegend, getCountryExpression } from '../../../utils';
import Legend from '../../Legend';

// import MapboxDraw from "@mapbox/mapbox-gl-draw";
// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

// TODO requires massive refactoring !!!!!

const DiffMap = ({
    data = [],
    size = 'large',
    unit = '',
    options: {
        interactive = false,
        animated = true,
        legendCollapsible = false,
    },
    onClick,
    keyResolver,
    theme = {
        legend: {},
    },
}: IMapProps): JSX.Element => {
    const mapContainer = useRef(null);
    const { map, isLoaded } = useMap({
        ref: mapContainer,
        accessToken: process.env.MAPBOX_TOKEN,
        options: {
            interactive,
            style: process.env.MAPBOX_STYLE,
        },
    });
    useFullscreen(map, size);
    useCountryHover({
        map,
        isLoaded,
        onClick,
        data,
        disabled: size === 'small' || size === 'medium',
    });
    const { bbox } = useLines({
        map,
        isLoaded,
        unit,
        data,
        keyResolver,
        expression: getCountryExpression(theme.legend?.arrows),
        size,
    });

    useFitBounds({
        map,
        animated,
        bboxes: [bbox],
        size,
    });

    // const Draw = new MapboxDraw();

    // map.addControl(Draw, 'top-left');

    // map.on('draw.create', function (e) {
    //     console.log(e.features);
    //     console.log(Draw.getAll())
    // });

    return (
        <StyledMap ref={mapContainer} size={size}>
            <Legend
                collapsible={legendCollapsible}
                unit={unit}
                legend={[
                    {
                        type: 'groups',
                        values: getCountryLegend(theme.legend?.arrows),
                    },
                ]}
            />
        </StyledMap>
    );
};

export default DiffMap;
