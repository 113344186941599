import { Row, Col, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import BarChart from '@entsoe/charts/components/BarChart';
import MapContainer from '@entsoe/map/containers/Map/';
import { Type } from '@entsoe/map/types/type';

import { useRootStore } from '../../../context/rootContext';
import { ExplorerModeType } from '../../../types';
import { GridType } from '../../../types/grid';
import { UiType } from '../../../types/ui';
import { StyledCard } from './styles';

interface ICountryContainerProps {
    type: ExplorerModeType;
}

// TODO move to store->view or api
const TEMP_RESOLVER = {
    [GridType.NONE]: 'No investment after 2025',
    [GridType.IOSN]: 'Economic needs',
    [GridType.PORTFOLIO]: 'Existing projects are built',
};

const COLORS = {
    [GridType.NONE]: 'noGridColor',
    [GridType.IOSN]: 'ioSNGridColor',
    [GridType.PORTFOLIO]: 'portfolioGridColor',
};

const CountryContainer = ({ type }: ICountryContainerProps): JSX.Element => {
    const { explorerStore, countryStore } = useRootStore();
    const { selectedCountry, data, selectedIndicator, isLoading, year } =
        explorerStore;

    const { getCountryByCode } = countryStore;

    const [uiType, setUiType] = useState('');
    const [unit, setUnit] = useState();

    const getGlobalColors = () => {
        return {
            no: selectedIndicator?.options.colors.global[COLORS[GridType.NONE]],
            iosn: selectedIndicator?.options.colors.global[
                COLORS[GridType.IOSN]
            ],
            portfolio:
                selectedIndicator?.options.colors.global[
                    COLORS[GridType.PORTFOLIO]
                ],
        };
    };

    const getLegendColors = () => {
        return selectedIndicator?.options.colors.legend;
    };

    useEffect(() => {
        setUiType(selectedIndicator?.options[type].type);
        setUnit(selectedIndicator?.unit);
    }, [selectedIndicator]);

    const transformFlowData = rawData => {
        let transformedData = [];
        for (let gridType of [
            GridType.NONE,
            GridType.IOSN,
            GridType.PORTFOLIO,
        ]) {
            transformedData.push({
                key: gridType,
                data: rawData
                    .map(item => {
                        return {
                            key: item.key,
                            key2: item.value.key,
                            value: item.value.value.find(
                                item => item.key === gridType
                            ),
                        };
                    })
                    .filter(item => item.value)
                    .map(item => {
                        return {
                            key: item.key,
                            key2: item.key2,
                            value: item.value.value,
                        };
                    }),
            });
        }

        return transformedData;
    };

    const getUiView = () => {
        // TODO if no countryId return message (select country WIP)
        const countryId = selectedCountry?.code;
        switch (uiType) {
            // TODO refactor
            case UiType.FLOWMAP:
                let min = null;
                let max = null;
                switch (selectedIndicator?.id) {
                    case 12:
                        min = 0;
                        max = 100;
                        break;
                }
                return (
                    <>
                        {transformFlowData(data).map(item => (
                            <Col xs={24} sm={8} key={item.key}>
                                <StyledCard
                                    size="small"
                                    bordered={false}
                                    title={TEMP_RESOLVER[item.key as GridType]}
                                >
                                    <MapContainer
                                        type={Type.FLOW}
                                        size="small"
                                        data={item.data}
                                        unit={unit}
                                        max={
                                            max ??
                                            Math.max(
                                                ...item.data.map(
                                                    item => item.value
                                                )
                                            )
                                        }
                                        min={
                                            min ??
                                            Math.min(
                                                ...item.data.map(
                                                    item => item.value
                                                )
                                            )
                                        }
                                        options={{
                                            interactive: true,
                                            animated: false,
                                            clickable: false,
                                            legendCollapsible: true,
                                        }}
                                        keyResolver={countryCode => {
                                            return getCountryByCode(
                                                countryCode
                                            );
                                        }}
                                        theme={{
                                            color: getGlobalColors()[
                                                item.key as GridType
                                            ],
                                        }}
                                        isLoading={isLoading}
                                    />
                                </StyledCard>
                            </Col>
                        ))}
                    </>
                );
            case UiType.CHARTMAP:
                return (
                    <Col xs={24} sm={24}>
                        <MapContainer
                            type={Type.CHART}
                            size="medium"
                            data={data}
                            unit={unit}
                            options={{
                                hoverable: true,
                                animated: false,
                                interactive: true,
                                clickable: true,
                                legendCollapsible: true,
                            }}
                            keyResolver={countryCode => {
                                return getCountryByCode(countryCode);
                            }}
                            forceChart={true}
                            isLoading={isLoading}
                            theme={{
                                legend: getLegendColors(),
                            }}
                            extendedLegend={year === 2040}
                            tooltipTitle="Capacity increases need"
                        />
                    </Col>
                );
            case UiType.DIFFMAP:
                return (
                    <>
                        {transformFlowData(data).map(item => (
                            <Col xs={24} sm={8} key={item.key}>
                                <StyledCard
                                    size="small"
                                    bordered={false}
                                    title={TEMP_RESOLVER[item.key as GridType]}
                                >
                                    <MapContainer
                                        type={Type.DIFF}
                                        size="small"
                                        data={item.data}
                                        unit={unit}
                                        options={{
                                            interactive: true,
                                            animated: false,
                                            clickable: false,
                                            legendCollapsible: true,
                                        }}
                                        keyResolver={countryCode => {
                                            return getCountryByCode(
                                                countryCode
                                            );
                                        }}
                                        isLoading={isLoading}
                                        theme={{
                                            legend: getLegendColors(),
                                        }}
                                    />
                                </StyledCard>
                            </Col>
                        ))}
                    </>
                );
            case UiType.MAP:
                let legendTitle = '';
                switch (selectedIndicator?.id) {
                    case 15:
                        legendTitle =
                            'Nominal transmission capacity / installed RES';
                        break;
                    case 16:
                        legendTitle =
                            'Nominal transmission capacity / peak load';
                        break;
                }
                return (
                    <>
                        {data.map(item => (
                            <Col xs={24} sm={8} key={item.key}>
                                <StyledCard
                                    size="small"
                                    bordered={false}
                                    title={TEMP_RESOLVER[item.key as GridType]}
                                >
                                    <MapContainer
                                        type={Type.DEFAULT}
                                        size="small"
                                        data={[
                                            {
                                                key: countryId,
                                                label: item.key,
                                                value: item.value,
                                            },
                                        ]}
                                        max={Math.max(
                                            ...data.map(item => item.value)
                                        )}
                                        min={Math.min(
                                            ...data.map(item => item.value)
                                        )}
                                        options={{
                                            interactive: true,
                                            animated: false,
                                            clickable: false,
                                        }}
                                        theme={{
                                            color: getGlobalColors()[
                                                item.key as GridType
                                            ],
                                            legend: getLegendColors(),
                                        }}
                                        isLoading={isLoading}
                                        legendTitle={legendTitle}
                                    />
                                </StyledCard>
                            </Col>
                        ))}
                    </>
                );
            case UiType.BARCHART:
                return (
                    <Col span={24}>
                        <BarChart
                            size="small"
                            data={data}
                            unit={unit}
                            keyResolver={code => {
                                const resolved = getCountryByCode(code);
                                if (resolved === code) {
                                    return (
                                        TEMP_RESOLVER[code as GridType] ?? code
                                    );
                                }
                                return resolved;
                            }}
                            isLoading={isLoading}
                            theme={{
                                type: 'linear',
                                colors: getGlobalColors(),
                            }}
                        />
                    </Col>
                );
            case UiType.BARCHART2:
                //TODO refactor or?

                let noGrid = data.find(item => item.key === 'no')?.value ?? {};
                let iosnGrid =
                    data.find(item => item.key === 'iosn')?.value ?? {};
                let portfolioGrid =
                    data.find(item => item.key === 'portfolio')?.value ?? {};

                let newData = [];
                for (const key in noGrid) {
                    let noValue = noGrid[key];
                    let iosnValue = iosnGrid[key];
                    let portfolioValue = portfolioGrid[key];

                    // TODO
                    if (noValue > 0 || iosnValue > 0 || portfolioValue > 0) {
                        newData.push({
                            key,
                            no: noValue,
                            iosn: iosnValue,
                            portfolio: portfolioValue,
                        });
                    }
                }

                return (
                    <Col span={24}>
                        <BarChart
                            size="small"
                            unit={unit}
                            data={newData}
                            keys={['no', 'iosn', 'portfolio']}
                            groupMode="grouped"
                            legend={true}
                            isLoading={isLoading}
                            theme={{
                                type: 'grouped',
                                colors: getGlobalColors(),
                            }}
                            keyResolver={code => {
                                const resolved = getCountryByCode(code);
                                if (resolved === code) {
                                    return (
                                        TEMP_RESOLVER[code as GridType] ?? code
                                    );
                                }
                                return resolved;
                            }}
                        />
                    </Col>
                );
            default:
                return <Skeleton paragraph={{ rows: 6 }} active={true} />;
        }
    };

    return (
        <>
            <Row gutter={[16, 0]}>{getUiView()}</Row>
        </>
    );
};

export default observer(CountryContainer);
