import styled from 'styled-components';

import { ISlideProps } from '.';

export const Slide = styled.img.attrs(({ image }: ISlideProps) => ({
    src: image,
}))`
    width: 953px;
    height: 537px;
    border: 1px solid #000;
`;

export const SlideInfo = styled.div`
    margin: 10px 0;
`;

export const SlideNumber = styled.div`
    color: #4b4748;
`;

export const NextSlide = styled.div`
    font-weight: bold;
    color: #0f218b;
    cursor: pointer;
`;
