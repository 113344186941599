import { tint } from 'polished';
import { useEffect, useRef, useState } from 'react';

import { StyledMap } from '../../../containers/Map/styles';
import { useCountryHover } from '../../../hooks/useCountryHover';
import { useFitBounds } from '../../../hooks/useFitBounds';
import { useFlows } from '../../../hooks/useFlows';
import { useFullscreen } from '../../../hooks/useFullscreen';
import { useMap } from '../../../hooks/useMap';
import { IMapProps } from '../../../types';
import Legend from '../../Legend';

const FlowMap = ({
    data = [],
    max,
    min,
    size = 'large',
    unit = '',
    options: {
        interactive = false,
        animated = true,
        legendCollapsible = false,
    },
    onClick,
    keyResolver,
    theme = {
        color: '#5e73ed',
    },
}: IMapProps): JSX.Element => {
    const mapContainer = useRef(null);
    const [minMaxValues, setMinMaxValues] = useState([0, 0]);

    const { map, isLoaded } = useMap({
        ref: mapContainer,
        accessToken: process.env.MAPBOX_TOKEN,
        options: {
            interactive,
            style: process.env.MAPBOX_STYLE,
        },
    });

    useFullscreen(map, size);
    useCountryHover({
        map,
        isLoaded,
        onClick,
        data,
        disabled: size === 'small' || size === 'medium',
    });
    const { bbox } = useFlows({
        map,
        isLoaded,
        data,
        keyResolver,
        theme,
        unit,
        min,
        max,
        size,
    });

    useFitBounds({
        map,
        animated,
        bboxes: [bbox],
        size,
    });

    const popuplateData = () => {
        if (!data.length) {
            return;
        }

        setMinMaxValues([
            min ?? Math.min(...data.map(item => item.value)),
            max ?? Math.max(...data.map(item => item.value)),
        ]);
    };

    useEffect(() => {
        if (data && isLoaded) {
            popuplateData();
        }
    }, [data, isLoaded]);

    return (
        <StyledMap ref={mapContainer} size={size}>
            <Legend
                collapsible={legendCollapsible}
                unit={unit}
                legend={[
                    {
                        type: 'interpolation',
                        values: [
                            {
                                valueFrom: minMaxValues[0],
                                valueTo: minMaxValues[1],
                                colorFrom: tint(0.6, theme.color),
                                colorTo: theme.color,
                            },
                        ],
                    },
                ]}
            />
        </StyledMap>
    );
};

export default FlowMap;
