import mapboxgl from 'mapbox-gl';
import { invert, tint } from 'polished';
import { useEffect } from 'react';

import { IUseCountryExpressionProps } from '../types';
import { usePopup } from './usePopup';

export const useCountryExpression = ({
    type = 'case',
    map,
    isLoaded = false,
    min,
    max,
    data = [],
    keyResolver = value => value,
    unit,
    theme = {
        color: '#5e73ed',
    },
    popup = true,
    popupPrefix = '',
    size,
    expression,
    filter,
    resolveValue,
}: IUseCountryExpressionProps): void => {
    const SOURCE_ID = 'source-country-expression';
    const LAYER_ID = 'country-expression-layer';

    usePopup({
        map,
        isLoaded,
        layer: LAYER_ID,
        enabled: popup,
        size,
    });

    const getExpression = (minValue, maxValue): mapboxgl.FillPaint => {
        switch (type) {
            case 'case':
                return {
                    'fill-outline-color': '#dfdfdf',
                    'fill-color': [
                        'case',
                        ['!=', ['feature-state', 'value'], null],
                        [
                            'case',
                            ['>', ['feature-state', 'value'], 0],
                            theme.color,
                            invert(theme.color),
                        ],
                        'rgba(255,255,255,0)',
                    ],
                };
            case 'group':
                return {
                    'fill-outline-color': '#dfdfdf',
                    'fill-color': [
                        'case',
                        ['!=', ['feature-state', 'value'], null],
                        expression,
                        // [
                        //     'case',
                        //     ['>', ['feature-state', 'value'], 0],
                        //     theme.color,
                        //     invert(theme.color),
                        // ],
                        'rgba(255,255,255,0)',
                    ],
                };
            case 'interpolate':
                return {
                    'fill-outline-color': '#dfdfdf',
                    'fill-color': [
                        'case',
                        ['!=', ['feature-state', 'value'], null],
                        [
                            'interpolate',
                            ['linear'],
                            ['feature-state', 'value'],
                            minValue - 0.001,
                            tint(0.5, theme.color),
                            maxValue,
                            theme.color,
                        ],
                        'rgba(255,255,255,0)',
                    ],
                };
        }
    };

    const clearSources = (removeSource: boolean) => {
        if (map.getLayer(LAYER_ID)) {
            map.removeLayer(LAYER_ID);
            map.removeFeatureState({
                source: SOURCE_ID,
                sourceLayer: 'country_boundaries',
            });
        }

        if (map.getSource(SOURCE_ID)) {
            // removeSource && map.removeSource(SOURCE_ID);
        }
    };

    const addLayer = (minValue, maxValue) => {
        map.addLayer(
            {
                id: LAYER_ID,
                type: 'fill',
                source: SOURCE_ID,
                'source-layer': 'country_boundaries',
                paint: getExpression(minValue, maxValue),
            },
            map.getLayer('hover-layer') ? 'hover-layer' : 'country-label'
        );
    };

    useEffect(() => {
        if (!map || !isLoaded) {
            return;
        }

        // clearSources(true);

        map.addSource(SOURCE_ID, {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
            promoteId: 'iso_3166_1',
        });

        return () => {
            clearSources(true);
        };
    }, [map, isLoaded]);

    useEffect(() => {
        if (isLoaded) {
            clearSources(false);

            const filteredData = filter ? filter(data) : data;

            if (!filteredData.length) {
                return;
            }

            const maxValue =
                max ?? Math.max(...filteredData.map(item => item.value));
            const minValue =
                min ?? Math.min(...filteredData.map(item => item.value));

            for (const item of filteredData) {
                map.setFeatureState(
                    {
                        source: SOURCE_ID,
                        sourceLayer: 'country_boundaries',
                        id: item.key,
                    },
                    {
                        key: item.key,
                        value: item.value,
                        popup: {
                            title: `${keyResolver(item.key)}`,
                            values: [
                                `${popupPrefix}${
                                    resolveValue
                                        ? resolveValue(item.value)
                                        : item.value
                                } ${unit}`,
                            ],
                        },
                    }
                );
            }

            addLayer(minValue, maxValue);
        }
    }, [data, isLoaded]);
};
