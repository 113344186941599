import { bbox as turfBbox, points as turfPoints } from '@turf/turf';
import { useEffect, useState } from 'react';

import { IUseFitBounds } from '../types';

export const useFitBounds = ({
    map,
    animated,
    bboxes = [],
    size = 'large',
}: IUseFitBounds): void => {
    const [activeBounds, setActiveBounds] = useState([]);
    useEffect(() => {
        if (!map) {
            return;
        }
        let bounds = [];
        bboxes.forEach(bbox => {
            if (bbox && bbox.length) {
                bounds = bounds.concat(bbox);
            }
        });
        bounds = bounds.length
            ? turfBbox(turfPoints(bounds))
            : [-30.585937, 27.293689, 44.912109, 69.869892];
        if (JSON.stringify(bounds) !== JSON.stringify(activeBounds)) {
            setActiveBounds(bounds);
            map.fitBounds(bounds, {
                padding:
                    size === 'small'
                        ? { top: 20, bottom: 60, right: 20, left: 20 }
                        : 20,
                maxZoom: 10,
                animate: animated,
            });
        }
    }, [map, bboxes]);
};
