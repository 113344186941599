import { ButtonProps as NativeButtonProps } from 'antd/lib/button/button';
import { useState } from 'react';

import { StyledButton } from './styles';

export type CustomButtonProps = {
    color?: 'red' | 'orange';
    hoverIcon?: React.ReactNode;
    children: React.ReactNode;
};

type ButtonProps = CustomButtonProps & NativeButtonProps;

const Button = ({
    color = 'red',
    children,
    hoverIcon,
    icon,
    ...rest
}: ButtonProps): JSX.Element => {
    const [activeIcon, setActiveIcon] = useState(icon);
    const additionalProps = hoverIcon
        ? {
              onMouseEnter: () => setActiveIcon(hoverIcon),
              onMouseLeave: () => setActiveIcon(icon),
          }
        : {};
    return (
        <>
            <StyledButton
                color={color}
                icon={activeIcon}
                {...rest}
                {...additionalProps}
            >
                {children}
            </StyledButton>
        </>
    );
};

export default Button;
