import React from 'react';

import HttpProgress from '@entsoe/app-base/components/HttpProgress';
import Menu from '@entsoe/app-base/components/Layout/Menu';

import { IMenuProps } from '../../typings';
import { Container } from '../Container';
import Layout, { Header, Footer, Content } from './styles';

// TODO add routeConfig type
export interface IAppLayoutProps {
    children: React.ReactNode;
    menuConfig: IMenuProps;
}

const AppLayout = ({ children, menuConfig }: IAppLayoutProps): JSX.Element => {
    return (
        <Layout>
            <HttpProgress />
            {window.location.pathname === "/" ? (
                <Header>
                    <Menu {...menuConfig} />
                </Header>
                ) : null}
            
            <Content className="site-layout">{children}</Content>
            {window.location.pathname === "/" ? (
                <Footer>
                    <p>
                        ENTSO-E - The european network for transmission system
                        operators electricity is a non-profit organisation, © 2022
                        All rights reserved.
                    </p>
                    <p>
                        Provide website feedback to{' '}
                        <a href="mailto:info@entsoe.eu">info@entsoe.eu</a>
                    </p>
                </Footer>
                 ) : null}
        </Layout>
    );
};

export default AppLayout;
