import { Result, Alert } from 'antd';
import { ResultStatusType } from 'antd/lib/result';

type Size = 'small' | 'medium' | 'large';

interface IErrorProps {
    size: Size;
    message?: string;
    extra?: React.ReactNode;
    status?: ResultStatusType;
    onRetry?(): void;
}

const Error = ({
    size,
    message = 'Sorry, something went wrong',
    status = 500,
    ...params
}: IErrorProps): JSX.Element => {
    switch (size) {
        case 'small':
            return <Alert message={message} type="error" {...params}></Alert>;
        case 'medium':
            return <Result status="error" title={message} {...params} />;
        case 'large':
            return (
                <Result
                    status={status}
                    title={message}
                    {...params}
                    // extra={<Button type="primary">Retry</Button>
                />
            );
    }
};

export default Error;
