import { CloseCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Grid } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { scroller } from 'react-scroll';

import Card from '@entsoe/app-base/components/Layout/Card';
import Sticky from '@entsoe/app-base/components/Sticky';

import CountrySelector from '../../../components/Configuration/CountrySelector';
// import DirectionSelector from '../../../components/Configuration/DirectionSelector';
import GridSelector from '../../../components/Configuration/GridSelector';
import IndicatorSelector from '../../../components/Configuration/IndicatorSelector';
import RegionSelector from '../../../components/Configuration/RegionSelector';
import YearSelector from '../../../components/Configuration/YearSelector';
import { useRootStore } from '../../../context/rootContext';
import { StyledDrawer } from './styles';

interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}

const Configuration = () => {
    const { explorerStore } = useRootStore();

    const { useBreakpoint } = Grid;

    const [isRegionSelectorVisible, setIsRegionSelectorVisible] =
        useState(false);
    const [isCountrySelectorVisible, setIsCountrySelectorVisible] =
        useState(false);
    // const [isDirectionSelectorVisible, setIsDirectionSelectorVisible] =
    //     useState(false);
    const [isGridSelectorVisible, setIsGridSelectorVisible] = useState(false);

    const screens = useBreakpoint();

    const [isDrawerVisible, setIsDrawerVisible] = useState(false);

    const onDrawerToggle = () => {
        setIsDrawerVisible(!isDrawerVisible);
    };

    const onDrawerClose = () => {
        setIsDrawerVisible(false);
    };

    const {
        year,
        grid,
        direction,
        selectedIndicator,
        selectedRegion,
        selectedCountry,
        setSelectedIndicator,
        setSelectedRegion,
        setSelectedCountry,
        setYear,
        setGrid,
        setDirection,
        isCountryMode,
        isLoading,
        isRegionalMode,
    } = explorerStore;

    const [configurationForm] = Form.useForm();

    const onChange = (allFields: FieldData[]) => {
        allFields
            .filter(field => field.touched)
            .forEach(field => {
                switch (field.name[0]) {
                    case 'year':
                        setYear(field.value);
                        break;
                    case 'selectedIndicator':
                        setSelectedIndicator(field.value);
                        break;
                    case 'selectedCountry':
                        setSelectedCountry(field.value);
                        break;
                    case 'selectedRegion':
                        setSelectedRegion(field.value);
                        break;
                    case 'grid':
                        setGrid(field.value);
                        break;
                    case 'direction':
                        setDirection(field.value);
                        break;
                }
            });
    };

    useEffect(() => {
        configurationForm.setFieldsValue({
            year,
        });
    }, [year]);

    useEffect(() => {
        configurationForm.setFieldsValue({
            grid,
        });
    }, [grid]);

    useEffect(() => {
        configurationForm.setFieldsValue({
            selectedCountry: selectedCountry?.code,
        });
    }, [selectedCountry]);

    useEffect(() => {
        configurationForm.setFieldsValue({
            selectedRegion: selectedRegion?.id,
        });
    }, [selectedRegion]);

    useEffect(() => {
        configurationForm.setFieldsValue({
            selectedIndicator: selectedIndicator?.id,
        });
    }, [selectedIndicator]);

    // TODO use view
    useEffect(() => {
        // setIsDirectionSelectorVisible(selectedIndicator?.options.hasDirection);
        setIsGridSelectorVisible(selectedIndicator?.options.hasGrid);
    }, [selectedIndicator]);

    const getProps = () => {
        return {
            mask: !screens.md,
            visible: screens.md ? true : isDrawerVisible,
            getContainer: screens.md ? false : undefined,
            placement: screens.md ? 'right' : 'bottom',
            style: screens.md
                ? {
                      position: 'absolute',
                      height: 'auto',
                  }
                : { position: 'fixed' },
            width: '100%',
            height: screens.md ? '100%' : 'auto',
        };
    };

    return (
        <>
            <Sticky
                onClick={() => {
                    scroller.scrollTo('explorer-inner', {
                        duration: 200,
                        delay: 0,
                        smooth: 'easeInOutQuad',
                        offset: -82,
                    });
                    onDrawerToggle();
                }}
                visible={!isDrawerVisible}
                bound="explorerTabs"
            >
                <UnorderedListOutlined />
            </Sticky>

            <StyledDrawer
                maskClosable={true}
                forceRender={true}
                closeIcon={<CloseCircleOutlined />}
                onClose={onDrawerClose}
                closable={false}
                {...getProps()}
            >
                <Card>
                    <Form
                        form={configurationForm}
                        name="configurationForm"
                        layout="vertical"
                        onFieldsChange={(_, allFields) => onChange(allFields)}
                        initialValues={{
                            year,
                            grid,
                            direction,
                            selectedCountry: selectedCountry?.code,
                            selectedIndicator: selectedIndicator?.id,
                            selectedRegion: selectedRegion?.id,
                        }}
                    >
                        <fieldset disabled={isLoading}>
                            <YearSelector />
                            <IndicatorSelector />
                            <RegionSelector hidden={!isRegionalMode} />
                            <GridSelector
                                hidden={
                                    !(isGridSelectorVisible && !isCountryMode)
                                }
                            />
                            {/* <DirectionSelector hidden={!isDirectionSelectorVisible} /> */}
                            <CountrySelector hidden={!isCountryMode} />
                        </fieldset>
                    </Form>
                    <Row gutter={[16, 16]}>
                        {/* {isCountryMode && (
                    <Col span={24}>
                        <Button
                            type="primary"
                            block
                            style={{
                                whiteSpace: 'normal',
                                height: 'auto',
                                marginBottom: '10px',
                            }}
                        >
                            Download country factsheet in PDF
                        </Button>
                    </Col>
                )} */}
                        <Col span={24}>
                            <Button
                                type="primary"
                                block
                                href={`${process.env.SERVER_API}/DataExport/xls`}
                                target={'_blank'}
                            >
                                Download data
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </StyledDrawer>
        </>
    );
};

export default observer(Configuration);
