import { parseToRgb } from 'polished';
import { useEffect, useState } from 'react';

import centersGeoJson from '../geojson/centers.json';
import { IUseMapData, IUseChartsProps } from '../types';
import { usePopup } from './usePopup';

export const useCharts = ({
    map,
    isLoaded = false,
    data = [],
    keyResolver = value => value,
    unit,
    theme = {
        legend: [],
    },
    forceChart = false,
    size,
}: IUseChartsProps): IUseMapData => {
    const SOURCE_ID = 'source-charts';
    const LAYER_ID = 'layer-charts';

    usePopup({
        map,
        isLoaded,
        layer: LAYER_ID,
        size,
    });

    const [bbox, setBbox] = useState<number[][]>();

    const clearSources = (removeSource: boolean) => {
        if (map.getLayer(LAYER_ID)) {
            map.removeLayer(LAYER_ID);
        }

        if (map.getSource(SOURCE_ID)) {
            map.removeFeatureState({
                source: SOURCE_ID,
            });
            removeSource && map.removeSource(SOURCE_ID);
        }
    };

    const getBaseColor = value => {
        // /parseToRgb

        const color = [132, 60, 12, 210];
        const len = theme.legend.length;
        if (value && len > 1) {
            for (let idx = 0; idx < len; idx++) {
                if (value < theme.legend[idx].threshold) {
                    const rgb = parseToRgb(theme.legend[idx].color);
                    return [rgb.red, rgb.green, rgb.blue, 255];
                }
            }
            if (!theme.legend[len - 1].threshold) {
                const rgb = parseToRgb(theme.legend[len - 1].color);
                return [rgb.red, rgb.green, rgb.blue, 255];
            }
            //TODO check if empty, then gt
            return color;
        } else {
            return color;
        }
    };

    const getChartImage = (value1, value2, maxValue) => {
        const maxWidth = 20;
        const maxHeight = 80;
        const minHeight = 10;
        const bytesPerPixel = 4;

        // TODO add exponent?
        const bar1Height =
            value1 == 0
                ? 0
                : Math.round(
                      minHeight + (value1 / maxValue) * (maxHeight - minHeight)
                  );
        const bar2Height =
            value2 == 0
                ? 0
                : Math.round(
                      minHeight + (value2 / maxValue) * (maxHeight - minHeight)
                  );

        const height = Math.max(bar1Height, bar2Height);

        const skip1Height = height - bar1Height;
        const skip2Height = height - bar2Height;

        const width = value1 === 0 || value2 === 0 ? maxWidth / 2 : maxWidth;

        const image = new Uint8Array(width * height * bytesPerPixel);

        // TODO parse
        // TODO use lighten instead!?
        const color1 = getBaseColor(value1);
        const color2 = getBaseColor(value1);
        const color3 = [0, 0, 0, 0];

        const getColor = (x, y, val1, val2) => {
            let color = color3;
            if (val1 == 0) {
                color = y > skip2Height ? color2 : color3;
            } else if (val2 == 0) {
                color = y > skip1Height ? color1 : color3;
            } else {
                if (x < maxWidth / 2) {
                    color = y > skip1Height ? color1 : color3;
                } else {
                    color = y > skip2Height ? color2 : color3;
                }
            }
            return color;
        };

        // T2B{L2R}
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const offset = (y * width + x) * bytesPerPixel;
                const color = getColor(x, y, value1, value2);

                image[offset + 0] = color[0];
                image[offset + 1] = color[1];
                image[offset + 2] = color[2];
                image[offset + 3] = color[3];
            }
        }

        return { width: width, height: height, data: image };
    };

    const addCharts = () => {
        map.listImages()
            .filter(item => /^[A-Z]{2}$/g.test(item))
            .forEach(item => map.removeImage(item));

        const chartData = data.filter(
            item => item.key && item.key === item.key2
        );

        if (chartData.length === 0) {
            return;
        }

        // TODO confirm static maxes
        // const maxValue1 = Math.max(
        //     ...data
        //         .map(item => item.value[Object.keys(item.value).sort()[0]])
        //         .filter(value => value > 0)
        // );

        // const maxValue2 = Math.max(
        //     ...data
        //         .map(item => item.value[Object.keys(item.value).sort()[1]])
        //         .filter(value => value > 0)
        // );
        const maxValue = Math.max(
            ...data
                .map(item => item.value['CO2-free peaking flexibility'])
                .filter(value => value > 0)
        );

        for (const item of chartData) {
            // TODO proper cleanup when data chages
            // const [key1, key2] = Object.keys(item.value).sort();
            // const value1 = item.value[key1];
            // const value2 = item.value[key2];

            const value = item.value['CO2-free peaking flexibility'];
            map.addImage(item.key, getChartImage(value, 0, maxValue));
        }
    };

    const addLayer = () => {
        map.addLayer({
            id: LAYER_ID,
            type: 'symbol',
            source: SOURCE_ID,
            minzoom: 1.99,
            // TODO dynamic
            ...(!forceChart && {
                filter: [
                    'step',
                    ['zoom'],
                    ['<=', ['number', ['get', 'zoom']], 2],
                    2,
                    ['<=', ['number', ['get', 'zoom']], 3],
                    3,
                    ['<=', ['number', ['get', 'zoom']], 4],
                    4,
                    ['<=', ['number', ['get', 'zoom']], 5],
                ],
            }),
            layout: {
                'icon-image': ['get', 'id'],
                'symbol-avoid-edges': true,
                'icon-ignore-placement': true,
                'icon-anchor': 'bottom',
            },
        });

        addCharts();
    };

    useEffect(() => {
        if (!map || !isLoaded) {
            return;
        }

        clearSources(true);

        map.addSource(SOURCE_ID, {
            type: 'geojson',
            data: centersGeoJson,
            promoteId: 'id',
        });

        return () => {
            clearSources(true);
        };
    }, [map, isLoaded]);

    useEffect(() => {
        const boundaries = [];

        if (isLoaded) {
            clearSources(false);

            if (!data.length) {
                return;
            }

            addLayer(
                Math.min(
                    ...data.map(item => item.value).filter(value => value > 0)
                ),
                Math.max(
                    ...data.map(item => item.value).filter(value => value > 0)
                )
            );

            for (const item of data) {
                if (item.value) {
                    if (item.key && item.key === item.key2) {
                        const feature = centersGeoJson.features.find(
                            el => el.id === item.key
                        );

                        if (feature) {
                            boundaries.push(feature?.geometry.coordinates);
                        }

                        //crappy api
                        const value = {
                            'CO2-free peaking flexibility':
                                item.value['CO2-free peaking flexibility'],
                        };

                        map.setFeatureState(
                            {
                                source: SOURCE_ID,
                                id: item.key,
                            },
                            {
                                value:
                                    item.key == item.key2
                                        ? Object.entries(value).map(val => {
                                              return {
                                                  key: val[0],
                                                  value: val[1],
                                              };
                                          })
                                        : null,
                                key: item.key,
                                hoverable: true,
                                popup: {
                                    title: `${keyResolver(item.key)}`,
                                    values: Object.entries(value).map(
                                        val => `${val[0]}: ${val[1]} ${unit}`
                                    ),
                                },
                            }
                        );
                    }
                }
            }
            setBbox(boundaries);
        }
    }, [data, isLoaded]);

    return {
        bbox,
    };
};
