import { useRef } from 'react';

import { StyledMap } from '../../../containers/Map/styles';
import { useCharts } from '../../../hooks/useCharts';
import { useCountryExpression } from '../../../hooks/useCountryExpression';
import { useCountryHover } from '../../../hooks/useCountryHover';
import { useFitBounds } from '../../../hooks/useFitBounds';
import { useFullscreen } from '../../../hooks/useFullscreen';
import { useLines } from '../../../hooks/useLines';
import { useMap } from '../../../hooks/useMap';
import { IMapProps } from '../../../types';
import { getCountryLegend, getCountryExpression } from '../../../utils';
import Legend, { getGroupItemText } from '../../Legend';

const ChartMap = ({
    data = [],
    size = 'large',
    unit = '',
    options: {
        interactive = false,
        animated = true,
        legendCollapsible = false,
    },
    onClick,
    keyResolver,
    forceChart = false,
    theme = {
        legend: {},
    },
    extendedLegend = false,
    tooltipTitle,
}: IMapProps): JSX.Element => {
    const mapContainer = useRef(null);

    const { map, isLoaded } = useMap({
        ref: mapContainer,
        accessToken: process.env.MAPBOX_TOKEN,
        options: {
            interactive,
            style: process.env.MAPBOX_STYLE,
        },
    });

    useFullscreen(map, size);

    const { bbox } = useLines({
        map,
        isLoaded,
        unit,
        data,
        keyResolver,
        type: 'arrows',
        expression: getCountryExpression(theme.legend?.arrows),
        size,
        tooltipTitle,
    });

    const legend = getCountryLegend(theme.legend?.countries);

    const resolveValue = value => {
        let legendItem = legend[legend.length - 1];
        for (let i = 0; i < legend.length; i++) {
            if (value < legend[i].valueTo) {
                legendItem = legend[i];
                break;
            }
        }

        return getGroupItemText({
            valueFrom: legendItem.valueFrom,
            valueTo: legendItem.valueTo,
            description: legendItem.description,
        });
    };

    useCountryExpression({
        type: 'group',
        map,
        isLoaded,
        data,
        keyResolver,
        popup: true,
        popupPrefix: 'Storage capacity increases after 2030: ',
        unit,
        size,
        expression: getCountryExpression(theme.legend?.countries),
        resolveValue,
        filter: d => {
            return d
                .filter(
                    item =>
                        item.key &&
                        item.key === item.key2 &&
                        item?.value['Storage Flexibility'] > 0
                )
                .map(item => {
                    return {
                        key: item.key,
                        value: item?.value['Storage Flexibility'],
                    };
                });
        },
    });

    const { bbox: chartsBbox } = useCharts({
        map,
        isLoaded,
        unit,
        data,
        keyResolver,
        forceChart,
        size,
        theme: {
            legend: theme.legend?.icons,
        },
    });

    const { bbox: countriesBbox } = useCountryHover({
        map,
        isLoaded,
        onClick,
        data,
        disabled: size === 'small' || size === 'medium',
    });

    useFitBounds({
        map,
        animated,
        bboxes: [bbox, countriesBbox, chartsBbox],
        size,
    });

    return (
        <StyledMap ref={mapContainer} size={size}>
            <Legend
                collapsible={legendCollapsible}
                unit={unit}
                legend={[
                    {
                        title: 'Cross-border capacity increases after 2025',
                        type: 'groups',
                        values: getCountryLegend(theme.legend?.arrows),
                    },
                ].concat(
                    extendedLegend
                        ? [
                              {
                                  title: 'Storage capacity increases after 2030',
                                  type: 'groups',
                                  arrow: 'block',
                                  values: getCountryLegend(
                                      theme.legend?.countries
                                  ),
                              },
                              {
                                  title: 'CO2-free peaking capacity increases after 2030',
                                  type: 'groups',
                                  arrow: 'block',
                                  values: getCountryLegend(theme.legend?.icons),
                              },
                          ]
                        : []
                )}
            />
        </StyledMap>
    );
};

export default ChartMap;
