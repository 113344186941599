// import NeedStore from './needStore';
import { types } from 'mobx-state-tree';

import CountryStore from './countryStore';
import ExplorerStore from './explorerStore';
import IndicatorStore from './indicatorStore';
import RegionStore from './regionStore';

export const RootModel = types.model({
    explorerStore: ExplorerStore,
    countryStore: CountryStore,
    regionStore: RegionStore,
    indicatorStore: IndicatorStore,
});

const rootStore = RootModel.create({
    explorerStore: {
        year: 2030,
        selectedRegion: 1,
        selectedIndicator: 3,
        selectedCountry: 'AL',
    },
    indicatorStore: {},
    regionStore: {},
    countryStore: {},
});

export default rootStore;
