import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { useRootStore } from '../../../context/rootContext';
import { GridType } from '../../../types/grid';

interface IGridSelectorProps {
    hidden?: boolean;
}

const GridSelector = ({ hidden = true }: IGridSelectorProps): JSX.Element => {
    const { explorerStore } = useRootStore();

    const { isLoading, selectedIndicator, setGrid, grid } = explorerStore;

    const { Option } = Select;

    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        switch (selectedIndicator?.id) {
            case 15:
            case 16:
                setGrid(GridType.NONE);
                setIsDisabled(true);
                break;
            default:
                setIsDisabled(false);
        }
    }, [selectedIndicator]);

    return (
        <Form.Item
            name="grid"
            label="Select grid configuration option"
            hidden={hidden}
        >
            <Select disabled={isLoading} value={grid}>
                <Option value={GridType.NONE}>No investment after 2025</Option>
                <Option value={GridType.IOSN} disabled={isDisabled}>
                    Economic needs
                </Option>
                <Option value={GridType.PORTFOLIO}>
                    Existing projects are built
                </Option>
            </Select>
        </Form.Item>
    );
};

export default observer(GridSelector);
