import { Switch as RouterSwitch, Route } from 'react-router-dom';

import Route404 from '../../routes/404/404';
// TODO add paths to tsconfig, aliases
import RouteRoot from '../../routes/Root/Root';
import EmbeddedRoot from '../../routes/embedded/Embedded';

// TODO router config as param
// TODO hook?
// TODO move to /routes as index

const Routes = () => {
    return (
        <RouterSwitch>
            <Route path="/" exact>
                <RouteRoot />
            </Route>
            <Route path="/Embedded">
                <EmbeddedRoot />
            </Route>
            <Route path="*">
                <Route404 />
            </Route>
        </RouterSwitch>
    );
};

export default Routes;
