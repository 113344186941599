import { types, cast } from 'mobx-state-tree';

export const Country = types.model('Country', {
    code: types.identifier,
    name: types.string,
});

const CountryStore = types
    .model('CountryStore', {
        data: types.optional(types.array(Country), []),
    })
    .actions(self => ({
        // TODO fix typings
        setData(data) {
            self.data = cast(data);
        },
    }))
    .views(self => {
        return {
            getCountryByCode(code: string) {
                return self.data.find(item => item.code === code)?.name ?? code;
            },
        };
    });

export default CountryStore;
