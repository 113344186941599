import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface IMarkdownStaticTextProps {
    value: string;
}

const MarkdownStaticText = ({
    value,
}: IMarkdownStaticTextProps): JSX.Element => {
    return <ReactMarkdown remarkPlugins={[remarkGfm]}>{value}</ReactMarkdown>;
};

export default MarkdownStaticText;
