import { LinkWrapper, StyledLink } from './styles';

interface ILinkProps {
    text: string;
    icon?: JSX.Element;
    url?: string;
}
const Link = ({ text, icon = <></>, url = '#' }: ILinkProps): JSX.Element => {
    return (
        <LinkWrapper href={`${url}`}>
            <span>
                {icon} {text}
            </span>
        </LinkWrapper>
    );
};

export const ScrollLink = StyledLink;

export default Link;
