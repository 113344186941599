import { types, cast } from 'mobx-state-tree';

export const Region = types.model('Region', {
    id: types.identifierNumber,
    name: types.string,
});

const RegionStore = types
    .model('RegionStore', {
        data: types.optional(types.array(Region), []),
    })
    .actions(self => ({
        // TODO fix typings
        setData(data) {
            self.data = cast(data);
        },
    }));

export default RegionStore;
