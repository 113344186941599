import ChartMap from '../../components/Map/ChartMap';
import DefaultMap from '../../components/Map/DefaultMap';
import DiffMap from '../../components/Map/DiffMap';
import FlowMap from '../../components/Map/FlowMap';
import NetMap from '../../components/Map/NetMap';
import { IMapContainerProps } from '../../types';
import { Type } from '../../types/type';
import { StyledMapContainer } from './styles';

export type MapSizes = 'small' | 'medium' | 'large';

const MapContainer = ({
    type,
    isLoading = true,
    ...mapProps
}: IMapContainerProps): JSX.Element => {
    const getMapComponent = (): JSX.Element => {
        switch (type) {
            case Type.FLOW:
                return <FlowMap {...mapProps} />;
            case Type.CHART:
                return <ChartMap {...mapProps} />;
            case Type.DIFF:
                return <DiffMap {...mapProps} />;
            case Type.NET:
                return <NetMap {...mapProps} />;
            case Type.DEFAULT:
                return <DefaultMap {...mapProps} />;
        }

        return <></>;
    };

    return (
        <StyledMapContainer size={mapProps.size} isLoading={isLoading}>
            {getMapComponent()}
        </StyledMapContainer>
    );
};

export default MapContainer;
