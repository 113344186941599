import { types, cast } from 'mobx-state-tree';

import {
    ExplorerModeType,
    ExplorerDirectionType,
    ExplorerGridType,
} from '../types';
import { DirectionType } from '../types/direction';
import { GridType } from '../types/grid';
import { ModeType } from '../types/mode';
import { Country } from './countryStore';
import { Indicator } from './indicatorStore';
import { Region } from './regionStore';

const ExplorerStore = types
    .model({
        mode: types.optional(types.string, ModeType.PANEU),
        year: types.optional(types.number, 2030),

        grid: types.optional(types.string, GridType.NONE),
        direction: types.optional(types.string, DirectionType.DESC),

        queryState: types.optional(
            types.enumeration('State', ['loading', 'done', 'error']),
            'loading'
        ),

        selectedIndicator: types.safeReference(Indicator),
        selectedCountry: types.safeReference(Country),
        selectedRegion: types.safeReference(Region),

        data: types.frozen(),
    })
    .actions(self => ({
        // TODO fix typings
        setData(data) {
            self.data = cast(data);
        },

        setMode(mode: ExplorerModeType) {
            self.mode = mode;
        },

        setYear(year: number) {
            self.year = year;
        },

        setGrid(grid: ExplorerGridType) {
            self.grid = grid;
        },

        setDirection(direction: ExplorerDirectionType) {
            self.direction = direction;
        },

        setSelectedIndicator(selectedIndicator: number) {
            self.selectedIndicator = selectedIndicator as any;
        },

        setSelectedRegion(selectedRegion: number) {
            self.selectedRegion = selectedRegion as any;
        },

        setSelectedCountry(selectedCountry: string) {
            self.selectedCountry = selectedCountry as any;
        },

        setQueryState(queryState: string) {
            self.queryState = queryState as any;
        },
    }))
    .views(self => ({
        get isPanEUMode() {
            return self.mode === ModeType.PANEU;
        },

        get isRegionalMode() {
            return self.mode === ModeType.REGIONAL;
        },

        get isCountryMode() {
            return self.mode === ModeType.COUNTRY;
        },

        get isLoading() {
            return self.queryState === 'loading';
        },

        get isError() {
            return self.queryState === 'error';
        },

        get isDone() {
            return self.queryState === 'done';
        },
    }));

export default ExplorerStore;
