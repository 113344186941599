import { Drawer } from 'antd';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)`
    .ant-drawer-content-wrapper {
        height: auto !important;
        box-shadow: none !important;

        .ant-drawer-content {
            .ant-drawer-header {
                position: absolute;
                z-index: 2000;
                background: transparent;
                padding: 8px;

                .ant-drawer-close {
                    color: rgba(0, 0, 0, 0.6);
                    font-weight: 800;
                }
            }
        }
        .ant-drawer-body {
            padding: 0;
        }
    }
`;
