import { Row, Col } from 'antd';
import { useState } from 'react';

import {
    LegendContainer,
    LegendGroup,
    Flow,
    Title,
    Label,
    Arrow,
    Block,
    LegendToggle,
} from './styles';

type GroupItem = {
    valueFrom?: number;
    valueTo?: number;
    color?: string;
    description?: string;
};

type InterpolationItem = {
    valueFrom: number;
    valueTo: number;
    colorFrom: string;
    colorTo: string;
};

type TextItem = {
    color: string;
    value: string;
};

type DefaultItem = {
    valueFrom: string;
    valueTo: string;
    colorFrom: string;
    colorTo: string;
};

type LegendItem = GroupItem | InterpolationItem | TextItem | DefaultItem;

type LegendData = {
    title?: string;
    values?: LegendItem[];
    type?: 'groups' | 'interpolation' | 'text' | 'default';
    arrow?: 'line' | 'block';
};

export interface ILegendProps {
    legend: LegendData[];
    unit?: string;
    collapsible?: boolean;
}

export const getGroupItemText = ({
    valueFrom,
    valueTo,
    description,
}: GroupItem): string => {
    if (description) {
        return description;
    }
    if (valueFrom === undefined && valueTo) {
        return `< ${valueTo}`;
    }
    if (valueTo === undefined && valueFrom) {
        return `> ${valueFrom}`;
    }
    if (valueFrom && valueTo) {
        return `${valueFrom} - ${valueTo}`;
    }
    return '';
};

// TODO make collapsible
const Legend = ({
    legend,
    unit,
    collapsible = false,
}: ILegendProps): JSX.Element => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCollapsed = () => {
        if (collapsible) {
            setIsCollapsed(!isCollapsed);
        }
    };
    // TODO < > etc

    const getLegendGrid = (
        item: LegendItem,
        key: number,
        type: 'groups' | 'interpolation' | 'text' | 'default',
        arrow: 'line' | 'block'
    ) => {
        switch (type) {
            case 'groups':
                return (
                    <Row align="middle" justify="space-between" key={key}>
                        <Col>
                            {arrow === 'line' && <Arrow color={item.color} />}
                            {arrow === 'block' && <Block color={item.color} />}
                        </Col>
                        <Col>
                            <Label>{getGroupItemText(item)}</Label>
                        </Col>
                        <Col>
                            <Label>{unit}</Label>
                        </Col>
                    </Row>
                );
            case 'interpolation':
                return (
                    <Row align="middle" key={key}>
                        <Col>
                            <Label>
                                {item.valueFrom} {unit}
                            </Label>
                        </Col>
                        <Col>
                            <Flow
                                colorFrom={item.colorFrom}
                                colorTo={item.colorTo}
                            />
                        </Col>
                        <Col>
                            <Label>
                                {item.valueTo} {unit}
                            </Label>
                        </Col>
                    </Row>
                );
            case 'default':
                return (
                    <Row align="middle" key={key}>
                        <Col>
                            <Label>{item.valueFrom}</Label>
                        </Col>
                        <Col>
                            <Flow
                                colorFrom={item.colorFrom}
                                colorTo={item.colorTo}
                            />
                        </Col>
                        <Col>
                            <Label>{item.valueTo}</Label>
                        </Col>
                    </Row>
                );
            case 'text':
                return (
                    <Row align="middle" justify="space-between" key={key}>
                        <Col>
                            <Block color={item.color} />
                        </Col>
                        <Col>
                            <Label>{item.value}</Label>
                        </Col>
                    </Row>
                );

            default:
                return <>...</>;
        }
    };
    return (
        <LegendContainer onClick={toggleCollapsed}>
            {collapsible && isCollapsed && <LegendToggle />}
            {(!collapsible || !isCollapsed) && (
                <>
                    {legend.map(
                        (
                            {
                                title,
                                values = [],
                                type = 'default',
                                arrow = 'line',
                            },
                            groupKey
                        ) => {
                            return (
                                <LegendGroup key={groupKey}>
                                    {title && <Title>{title}</Title>}
                                    {type === 'groups' && values.length < 2 && (
                                        <Label>
                                            Please specify 2 threshold values at
                                            least
                                        </Label>
                                    )}
                                    {type === 'groups' &&
                                        values.length > 1 &&
                                        values.map((item, key) => {
                                            return getLegendGrid(
                                                item,
                                                key,
                                                type,
                                                arrow
                                            );
                                        })}
                                    {type !== 'groups' &&
                                        values.map((item, key) => {
                                            return getLegendGrid(
                                                item,
                                                key,
                                                type,
                                                arrow
                                            );
                                        })}
                                </LegendGroup>
                            );
                        }
                    )}
                </>
            )}
        </LegendContainer>
    );
};

export default Legend;
