import { Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import MarkdownStaticText from '@entsoe/app-base/components/Layout/MarkdownStaticText';

import { useRootStore } from '../../context/rootContext';
import { ModeType } from '../../types/mode';

//TODO add fake preloader?, transition
interface IRegionalExplainerProps {}
const RegionalExplainer = (): JSX.Element => {
    const { explorerStore } = useRootStore();

    const { selectedIndicator, selectedRegion, selectedCountry, mode } =
        explorerStore;

    const [textValue, setTextValue] = useState(null);

    useEffect(() => {
        switch (mode) {
            case ModeType.PANEU:
                if (selectedIndicator?.id) {
                    setTextValue(selectedIndicator?.text.paneuText);
                }
                break;
            case ModeType.REGIONAL:
                if (selectedIndicator?.id && selectedRegion?.id) {
                    setTextValue(
                        selectedIndicator?.text.regionTexts[selectedRegion?.id]
                    );
                }
                break;
            case ModeType.COUNTRY:
                if (selectedIndicator?.id && selectedCountry?.code) {
                    setTextValue(
                        selectedIndicator?.text.countryTexts[
                            selectedCountry?.code
                        ]
                    );
                }
                break;
        }
    }, [selectedIndicator, selectedRegion, selectedCountry, mode]);

    return <MarkdownStaticText value={textValue ?? ''} />;
};

export default observer(RegionalExplainer);
