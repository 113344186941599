import httpClient from '../services/httpService';

export const getAllIndicators = () => {
    return httpClient.get('/indicators/visible');
};

export const getCountriesByIndicator = (selectedIndicatorId: number) => {
    return httpClient.get(`/countries/${selectedIndicatorId}`);
};

export const getRegionsByIndicator = () => {
    return httpClient.get('/regions/');
};

export const getData = (params: any) => {
    return httpClient.post('/data', params);
};

export const getText = (name: string) => {
    return httpClient.get(`/text/${name}`);
};
