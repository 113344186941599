import { Instance } from 'mobx-state-tree';
import { createContext, useContext } from 'react';

import rootStore, { RootModel } from '../stores/rootStore';

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

const RootProvider = ({ children }): JSX.Element => {
    const value = rootStore;

    return (
        <RootStoreContext.Provider value={value}>
            {children}
        </RootStoreContext.Provider>
    );
};

export const useRootStore = () => {
    const context = useContext(RootStoreContext);
    if (context === undefined) {
        throw new Error('useRootStore can only be used in RootProvider');
    }
    return context;
};

export default RootProvider;
