import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { useRootStore } from '../../../context/rootContext';
import { getAllIndicators } from '../../../services/apiService';

const IndicatorSelector = (): JSX.Element => {
    const { indicatorStore, explorerStore } = useRootStore();
    const [options, setOptions] = useState([]);

    const { setData } = indicatorStore;
    const {
        isLoading: isExplorerLoading,
        setSelectedIndicator,
        selectedIndicator,
    } = explorerStore;

    const { isLoading } = useQuery('indicators', () => getAllIndicators(), {
        onSuccess: ({ data }) => {
            setData(data);
            selectedIndicator ?? setSelectedIndicator(data[0].id);
            setOptions(
                data.map(item => {
                    return {
                        value: item.id,
                        label: item.name,
                    };
                })
            );
        },
    });

    return (
        <Form.Item name="selectedIndicator" label="Select an indicator">
            <Select
                loading={isLoading}
                options={options}
                disabled={isLoading || isExplorerLoading}
            />
        </Form.Item>
    );
};

export default observer(IndicatorSelector);
