import { Button } from 'antd';
import styled from 'styled-components';

const colors = {
    red: '#ff4d00',
    orange: '#F49900',
};

export const StyledButton = styled(Button)<{ color: 'red' | 'orange' }>`
    background-color: ${({ color }) => colors[color ?? 'red']};
    border: none;
    border-radius: 4px;
    margin: 10px 20px;
    &:hover,
    &:focus {
        background-color: ${({ color }) => colors[color ?? 'red']};
        opacity: 0.8;
    }
`;
