import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Element, scroller } from 'react-scroll';

import useDebounce from '@entsoe/app-base/hooks/useDebounce';

import { useRootStore } from '../../../context/rootContext';
import { getData } from '../../../services/apiService';
import { ModeType } from '../../../types/mode';
import CountryContainer from '../CountryContainer';
import ViewContainer from '../ViewContainer';
import { StyledTabPane, StyledCountryTabPane } from './styles';

const Explorer = observer(() => {
    const { explorerStore } = useRootStore();
    const {
        setMode,
        mode,
        year,
        grid,
        direction,
        selectedIndicator,
        selectedCountry,
        selectedRegion,
        setQueryState,
        setData,
        isLoading,
    } = explorerStore;

    const { refetch } = useQuery(
        [mode, year, grid, selectedIndicator, selectedRegion, selectedCountry],
        () => {
            setData([]);
            setQueryState('loading');
            return getData({
                mode,
                year,
                grid,
                indicatorId: selectedIndicator?.id,
                countryCode: selectedCountry?.code,
                regionId: selectedRegion?.id,
            });
        },
        {
            enabled: false,
            staleTime: 0,
            onError: () => {
                setQueryState('error');
            },
            onSuccess: ({ data }) => {
                setQueryState('done');
                setData(data.data);
                // scroller.scrollTo('explorer', {
                //     duration: 800,
                //     delay: 100,
                //     smooth: 'easeInOutQuad',
                //     offset: -64, // Scrolls to element + 50 pixels down the page
                // });
            },
        }
    );

    useEffect(() => {
        setData([]);
    }, [
        mode,
        year,
        grid,
        // direction,
        selectedIndicator,
        selectedRegion,
        selectedCountry,
    ]);

    useDebounce({
        callback: refetch,
        delay: 200,
        dependencies: [
            mode,
            year,
            grid,
            // direction,
            selectedIndicator,
            selectedRegion,
            selectedCountry,
        ],
    });

    return (
        <Tabs
            id="explorerTabs"
            defaultActiveKey={ModeType.PANEU}
            activeKey={mode}
            type="card"
            tabBarStyle={{
                fontWeight: 700,
            }}
            destroyInactiveTabPane={true}
            onTabClick={key => {
                if (mode !== key) {
                    setData([]);
                    setMode(key);
                }
            }}
        >
            <StyledTabPane
                tab="Pan European"
                key={ModeType.PANEU}
                disabled={isLoading}
            >
                <ViewContainer type={ModeType.PANEU} />
            </StyledTabPane>
            <StyledTabPane
                tab="By PCI Corridor"
                key={ModeType.REGIONAL}
                disabled={isLoading}
            >
                <ViewContainer type={ModeType.REGIONAL} />
            </StyledTabPane>
            <StyledCountryTabPane
                tab="By country"
                key={ModeType.COUNTRY}
                disabled={isLoading}
            >
                <CountryContainer type={ModeType.COUNTRY} />
            </StyledCountryTabPane>
        </Tabs>
    );
});

export default Explorer;
