import { LogoWrapper, LogoImage } from './styles';

export interface ILogoProps {
    variant?: 'light' | 'dark';
}

const Logo = ({ variant = 'dark' }: ILogoProps): JSX.Element => {
    return (
        <LogoWrapper>
            <LogoImage variant={variant} />
        </LogoWrapper>
    );
};

export default Logo;
