import styled from 'styled-components';

export const MenuToggle = styled.div`
    text-align: right;
    color: #0f218b;

    @media (min-width: 800px) {
        display: none;
    }
`;
