import { invert } from 'polished';
import { useRef } from 'react';

import { StyledMap } from '../../../containers/Map/styles';
import { useCountryExpression } from '../../../hooks/useCountryExpression';
import { useCountryHover } from '../../../hooks/useCountryHover';
import { useFitBounds } from '../../../hooks/useFitBounds';
import { useFullscreen } from '../../../hooks/useFullscreen';
import { useMap } from '../../../hooks/useMap';
import { IMapProps } from '../../../types';
import Legend from '../../Legend';

const NetMap = ({
    data = [],
    size = 'large',
    unit = '',
    options: {
        interactive = false,
        animated = true,
        legendCollapsible = false,
    },
    onClick,
    keyResolver,
    theme = {
        color: '#5e73ed',
    },
}: IMapProps): JSX.Element => {
    const mapContainer = useRef(null);
    const { map, isLoaded } = useMap({
        ref: mapContainer,
        accessToken: process.env.MAPBOX_TOKEN,
        options: {
            interactive,
            style: process.env.MAPBOX_STYLE,
        },
    });
    useFullscreen(map, size);
    useCountryExpression({
        type: 'case',
        theme,
        map,
        isLoaded,
        data,
        unit,
        keyResolver,
        size,
    });
    const { bbox: countryBbox } = useCountryHover({
        map,
        isLoaded,
        onClick,
        data,
        disabled: size === 'small' || size === 'medium',
    });

    useFitBounds({
        map,
        animated,
        bboxes: [countryBbox],
        size,
    });

    return (
        <StyledMap ref={mapContainer} size={size}>
            <Legend
                collapsible={legendCollapsible}
                unit={unit}
                legend={[
                    {
                        type: 'text',
                        values: [
                            {
                                value: 'Net Export',
                                color: theme.color,
                            },
                            {
                                value: 'Net Import',
                                color: invert(theme.color),
                            },
                        ],
                    },
                ]}
            />
        </StyledMap>
    );
};

export default NetMap;
