import { PopupItem, PopupTitle } from './styles';

export interface IPopupProps {
    title?: string;
    values: string[];
}

const Popup = ({ title, values = [] }: IPopupProps): JSX.Element => {
    if (!values) {
        return <></>;
    }
    return (
        <>
            {title && <PopupTitle>{title}</PopupTitle>}

            {values.map(item => {
                return <PopupItem key={item}>{item}</PopupItem>;
            })}
        </>
    );
};

export default Popup;
