import { IntroContainer } from './styles';

interface IIntroProps {
    children: React.ReactNode;
}

const Intro = ({ children }: IIntroProps): JSX.Element => {
    return <IntroContainer>{children}</IntroContainer>;
};

export default Intro;
