import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Title } = Typography;

export const StyledRow = styled(Row)`
    @media (max-width: 767px) {
        margin-left: -23px !important;
        margin-right: -23px !important;
    }
`;

export const StyledExplainerIcon = styled(QuestionCircleOutlined)`
    @keyframes rotating {
        0% {
            transform: rotate(0deg);
        }
        25% {
            transform: rotate(20deg) scale(1.1);
        }
        50% {
            transform: rotate(0deg);
        }
        75% {
            transform: rotate(-20deg) scale(1.1);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    transition: all 0.3s ease-in-out;
    animation: rotating 1.2s linear 2;
    color: #28acbf;
    &:hover {
        transform: rotate(360deg) scale(1.2);
    }
`;

export const TextContainer = styled.section`
    padding: 1rem 0;
    margin-top: 1rem;
    background-color: #edf2f7; ;
`;

// TODO extract Carousel component
export const Slide = styled.h4`
    height: 400px;
    color: #000;
    font-size: 64px;
    font-weight: bold;
    line-height: 400px;
    text-align: center;
    background: #edf2f7;
`;

export const StyledTitle = styled(Title)`
    color: #0f218b !important;
    margin: 30px 0 !important;
`;
