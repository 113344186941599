import { Form, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { useRootStore } from '../../../context/rootContext';
import { getCountriesByIndicator } from '../../../services/apiService';

interface ICountrySelectorProps {
    hidden?: boolean;
}

const CountrySelector = ({
    hidden = true,
}: ICountrySelectorProps): JSX.Element => {
    const { explorerStore, countryStore } = useRootStore();
    const [options, setOptions] = useState([]);

    const { setData } = countryStore;

    const {
        selectedIndicator,
        isLoading: isExplorerLoading,
        setSelectedCountry,
        selectedCountry,
    } = explorerStore;

    const { refetch, isLoading } = useQuery(
        ['countries', selectedIndicator?.id],
        () => getCountriesByIndicator(selectedIndicator?.id),
        {
            enabled: false,
            onSuccess: ({ data }) => {
                setData(data);
                if (
                    !selectedCountry ||
                    !data.find(item => selectedCountry?.code === item.code)
                ) {
                    setSelectedCountry(data[0].code);
                }
                setOptions(
                    data.map(item => {
                        return {
                            value: item.code,
                            label: item.name,
                        };
                    })
                );
            },
        }
    );

    useEffect(() => {
        if (selectedIndicator?.id) {
            refetch();
        }
    }, [selectedIndicator]);

    return (
        <Form.Item
            name="selectedCountry"
            label="Select country"
            hidden={hidden}
        >
            <Select
                loading={isLoading}
                options={options}
                disabled={isLoading || isExplorerLoading}
            />
        </Form.Item>
    );
};

export default observer(CountrySelector);
