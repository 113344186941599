import { Row, Col } from 'antd';
import { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { Slide, SlideNumber, NextSlide, SlideInfo } from './styles';

interface ICarouselProps {
    images?: string[];
}

export interface ISlideProps {
    image: string;
}

const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return (
        <NextSlide
            onClick={() => {
                swiper.slideNext();
            }}
        >
            Next slide ⟶
        </NextSlide>
    );
};

const SwiperButtonPrev = () => {
    const swiper = useSwiper();
    return (
        <NextSlide
            onClick={() => {
                swiper.slidePrev();
            }}
        >
            ⟵ Previous slide
        </NextSlide>
    );
};

const Carousel = ({ images = [] }: ICarouselProps): JSX.Element => {
    return (
        <Swiper
            slidesPerView={'auto'}
            centeredSlides={true}
            centerInsufficientSlides={true}
            spaceBetween={60}
            slidesOffsetBefore={0}
            slidesOffsetAfter={250}
            modules={[Pagination, Navigation]}
        >
            {images.map((image, idx) => {
                return (
                    <SwiperSlide key={idx}>
                        <SlideInfo>
                            <Row justify="space-between">
                                <Col>
                                    <SlideNumber>
                                        {idx + 1}/{images.length}
                                    </SlideNumber>
                                </Col>
                                <Col>
                                    {idx < images.length - 1 && (
                                        <SwiperButtonNext />
                                    )}
                                </Col>
                            </Row>
                        </SlideInfo>
                        <Slide image={image}></Slide>
                        <SlideInfo>
                            <Row justify="space-between">
                                <Col>{idx > 0 && <SwiperButtonPrev />}</Col>
                            </Row>
                        </SlideInfo>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};

export default Carousel;
