import { Link } from 'react-scroll';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
    color: #0f218b;
    font-size: 16px;
    /* font-weight: bold; */
    transition: color 0.5s;
    margin: 0 1em;
    max-width: 50px;
    &:hover {
        color: #28acbf;

        span {
            &:after {
                width: 100%;
            }
        }
    }

    span {
        position: relative;
        &:after {
            width: 200px;
            content: '';
            height: 3px;
            bottom: -10px;
            transition: 0.2s;
            position: absolute;
            left: 0;
            background-image: linear-gradient(30deg, #28acbf, #b3dff0);
            width: 0;
            z-index: 10;
        }
    }
`;

export const LinkWrapper = styled.a`
    color: #0f218b;
    font-size: 16px;
    /* font-weight: bold; */
    transition: color 0.5s;
    margin: 0 1em;
    max-width: 50px;
    &:hover {
        color: #28acbf;

        span {
            &:after {
                width: 100%;
            }
        }
    }

    span {
        position: relative;
        &:after {
            width: 200px;
            content: '';
            height: 3px;
            bottom: -10px;
            transition: 0.2s;
            position: absolute;
            left: 0;
            background-image: linear-gradient(30deg, #28acbf, #b3dff0);
            width: 0;
            z-index: 10;
        }
    }
`;
