import mapboxgl from 'mapbox-gl';
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import Popup, { IPopupProps } from '../components/Popup';
import { IUsePopupProps } from '../types';

const defaultOptions = {
    closeButton: false,
    closeOnClick: false,
};

export const usePopup = ({
    map,
    isLoaded,
    options,
    layer,
    enabled = true,
    size = 'large',
}: IUsePopupProps): mapboxgl.Popup | undefined => {
    const [popup, setPopup] = useState<mapboxgl.Popup>();
    const [popupData, setPopupData] = useState<IPopupProps>();

    const popupNode = document.createElement('div');

    useEffect(() => {
        if (popup && popupData) {
            ReactDOM.render(<Popup {...popupData} />, popupNode);
            popup.setDOMContent(popupNode).addTo(map);
        }
    }, [popupData]);

    useEffect(() => {
        if (isLoaded && popup && enabled) {
            map.on('mouseenter', layer, e => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mousemove', layer, e => {
                const { popup: _popupData } = e.features[0].state;
                if (_popupData) {
                    setPopupData(_popupData);
                    popup.setLngLat(e.lngLat);
                }
            });

            map.on('mouseleave', layer, () => {
                map.getCanvas().style.cursor = '';
                setPopupData(undefined);
                popup.remove();
            });
        }
    }, [isLoaded, popup, enabled]);

    useEffect(() => {
        if (popup) {
            return;
        }
        setPopup(
            new mapboxgl.Popup({
                ...defaultOptions,
                ...(size === 'small'
                    ? { className: 'mapbox-popup-small' }
                    : {}),
                ...options,
            })
        );
    }, [options]);

    return popup;
};
