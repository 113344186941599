import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useState } from 'react';

const defaultOptions = {
    style: 'mapbox://styles/mapbox/light-v10',
    center: [22.278889, 58.303889],
    zoom: 3,
    doubleClickZoom: false,
    interactive: true,
    cooperativeGestures: true,
    boxZoom: false,
    dragRotate: false,
    pitchWithRotate: false,
};

type Source = {
    id: string;
    source: mapboxgl.AnySourceData;
};

interface IUseMapProps {
    ref: React.RefObject<HTMLDivElement>;
    accessToken: string;
    options?: Partial<mapboxgl.MapboxOptions>;
    sources?: Source[];
}

interface IUseMap {
    map: mapboxgl.Map | undefined;
    isLoaded: boolean;
}

export const useMap = ({
    ref,
    accessToken,
    options,
    sources = [],
}: IUseMapProps): IUseMap => {
    const [map, setMap] = useState<mapboxgl.Map>();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        const container = ref.current;

        if (map || !container) {
            return;
        }
        mapboxgl.accessToken = accessToken;

        const mapInstance = new mapboxgl.Map({
            container,
            ...defaultOptions,
            ...options,
        });

        mapInstance.on('load', () => {
            setIsLoaded(true);
            sources.forEach(source => {
                mapInstance.addSource(source.id, source.source);
            });
        });

        mapInstance.touchZoomRotate.disableRotation();

        setMap(mapInstance);
    }, [map, ref, accessToken, options]);

    return {
        map,
        isLoaded,
    };
};
