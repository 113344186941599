import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadioGroup = styled(Radio.Group)`
    display: flex;
    flex-direction: row;

    .ant-radio-button-wrapper {
        width: 50%;
        text-align: center;
    }
`;
